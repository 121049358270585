<template>
  <div class="review-container">
    <div class="condition-header">
      <div class="condition">コメント</div>
      <label v-if="hasReplied" class="ts-checkbox checked">
        <input v-model="hasReplied" type="checkbox" />
        <v-icon color="#ee9d26" class="mr-1" size="13">fas fa-check</v-icon>
        返信済
      </label>
      <label v-else class="ts-checkbox">
        <input v-model="hasReplied" type="checkbox" />
        <v-icon color="#ccc" class="mr-1" size="13">fas fa-check</v-icon>
        返信済
      </label>

      <label v-if="hasNotReplied" class="ts-checkbox checked">
        <input v-model="hasNotReplied" type="checkbox" />
        <v-icon color="#ee9d26" class="mr-1" size="13">fas fa-check</v-icon>
        未返信
      </label>
      <label v-else class="ts-checkbox">
        <input v-model="hasNotReplied" type="checkbox" />
        <v-icon color="#ccc" class="mr-1" size="13">fas fa-check</v-icon>
        未返信
      </label>

      <div class="condition" style="margin-left: 20px">星</div>
      <label v-if="checkThisStar(1)" class="ts-checkbox checked">
        <v-icon color="#ee9d26" class="mr-1" size="13">fas fa-check</v-icon>
        <input v-model="hasStar" type="checkbox" :value="1" />
        ★1
      </label>
      <label v-else class="ts-checkbox">
        <v-icon color="#ccc" class="mr-1" size="13">fas fa-check</v-icon>
        <input v-model="hasStar" type="checkbox" :value="1" />
        ★1
      </label>

      <label v-if="checkThisStar(2)" class="ts-checkbox checked">
        <v-icon color="#ee9d26" class="mr-1" size="13">fas fa-check</v-icon>
        <input v-model="hasStar" type="checkbox" :value="2" />
        ★2
      </label>
      <label v-else class="ts-checkbox">
        <v-icon color="#ccc" class="mr-1" size="13">fas fa-check</v-icon>
        <input v-model="hasStar" type="checkbox" :value="2" />
        ★2
      </label>

      <label v-if="checkThisStar(3)" class="ts-checkbox checked">
        <v-icon color="#ee9d26" class="mr-1" size="13">fas fa-check</v-icon>
        <input v-model="hasStar" type="checkbox" :value="3" />
        ★3
      </label>
      <label v-else class="ts-checkbox">
        <v-icon color="#ccc" class="mr-1" size="13">fas fa-check</v-icon>
        <input v-model="hasStar" type="checkbox" :value="3" />
        ★3
      </label>

      <label v-if="checkThisStar(4)" class="ts-checkbox checked">
        <v-icon color="#ee9d26" class="mr-1" size="13">fas fa-check</v-icon>
        <input v-model="hasStar" type="checkbox" :value="4" />
        ★4
      </label>
      <label v-else class="ts-checkbox">
        <v-icon color="#ccc" class="mr-1" size="13">fas fa-check</v-icon>
        <input v-model="hasStar" type="checkbox" :value="4" />
        ★4
      </label>

      <label v-if="checkThisStar(5)" class="ts-checkbox checked">
        <v-icon color="#ee9d26" class="mr-1" size="13">fas fa-check</v-icon>
        <input v-model="hasStar" type="checkbox" :value="5" />
        ★5
      </label>
      <label v-else class="ts-checkbox">
        <v-icon color="#ccc" class="mr-1" size="13">fas fa-check</v-icon>
        <input v-model="hasStar" type="checkbox" :value="5" />
        ★5
      </label>
      <v-btn
        class="button is-primary apply-button ml-3"
        :disabled="selectedKeyword == '' ? true : false"
        @click="filterComment"
      >
        適用
      </v-btn>
    </div>
    <div class="data-table-wrap">
      <v-data-table
        :page="page"
        :headers="headers"
        :items="rows"
        :items-per-page="10"
        item-value="index"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          disableItemsPerPage: true,
          itemsPerPageOptions: [10, 20, 50, 100],
        }"
        :loading="loading"
        hide-default-footer
        class="review-search-table"
        item-key="index"
        show-expand
        :no-data-text="
          selectedKeyword === ''
            ? '上のキーワードのポジネガ分析から対象キーワードを選択してください'
            : '指定された条件にヒットするクチコミはありません'
        "
        expand-on-click
      >
        <!-- 通常行 -->
        <template #item.reviewSummary="{ item }">
          <p style="margin-bottom: 5px">{{ item.reviewSummary }}</p>
          <p v-if="item.reply && item.reply !== ''" style="margin-bottom: 10px">
            <v-icon color="primary" small class="reply-arrow">fas fa-level-up-alt</v-icon>
            <span style="color: #575757">
              {{ item.replySummary }}
            </span>
          </p>
        </template>
        <!-- 詳細行 -->
        <template #expanded-row="{ item, columns }">
          <tr>
            <td :colspan="columns.length" class="detail-row">
              <div class="detail-review">
                <div v-for="(text, index) in item.review?.split('\n') ?? []" :key="index">
                  {{ text }}
                  <br />
                </div>
              </div>
              <div v-if="item.reply && item.reply !== ''" style="display: flex">
                <v-icon color="primary" small class="reply-arrow">fas fa-level-up-alt</v-icon>
                <div class="detail-reply">
                  <div v-for="(text, index) in item.reply?.split('\n') ?? []" :key="index">
                    {{ text }}
                    <br />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <template #bottom />
      </v-data-table>
      <div v-if="loading" class="progress-circular-container">
        <v-progress-circular
          :size="50"
          :width="4"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <!-- ページネーション -->
    <v-pagination
      v-model="page"
      :length="pageCount"
      total-visible="10"
      @update:model-value="pageLoad"
    ></v-pagination>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, toNative } from "vue-facing-decorator";
import type { VDataTable } from "vuetify/components/VDataTable";
import { requiredAuth } from "@/helpers";
import type { SelectedTarget } from "@/components/shared/store-selector.vue";
import type {
  ControllersReviewsSearchInput,
  DomainReviewsSearch,
  DomainReviewItem,
} from "@/types/ls-api";
import dayjs from "dayjs";

type Header = Pick<VDataTable["headers"][number], "key" | "title" | "align" | "width" | "sortable">;
interface ReviewRow extends DomainReviewItem {
  index: number;
  reviewSummary: string;
  replySummary: string;
}

@Component({ components: {} })
class ReviewSearch extends Vue {
  @Prop({ type: String }) dateTo: string;
  @Prop({ type: String }) dateFrom: string;
  @Prop({ type: Object }) selectedTarget: SelectedTarget;
  @Prop({ type: String }) selectedKeyword: string;
  poiGroupId: number;
  reviewsSearch: DomainReviewsSearch = null;
  loading: boolean = false;
  page: number = 1;
  pageCount: number = 0;
  perPage: number = 10;
  totalCount: number = 0;
  hasReplied: boolean = false;
  hasNotReplied: boolean = false;
  hasStar: number[] = [];

  async filterComment(): Promise<void> {
    this.page = 1;
    if (this.selectedKeyword != null && this.selectedKeyword != "") {
      await this.fetchReviewSearch();
      this.makeReviewSearchRows();
    }
  }

  headers: Header[] = [
    { title: "", key: "data-table-expand", align: "center" },
    { title: "最終更新時刻", key: "updateTime", align: "start", width: "140px", sortable: false },
    { title: "初投稿時刻", key: "createTime", align: "start", width: "140px", sortable: false },
    { title: "店舗", key: "storeName", align: "center", width: "180px", sortable: false },
    { title: "星", key: "starRating", align: "center", width: "20px", sortable: false },
    { title: "コメント", key: "reviewSummary", align: "start", sortable: false },
  ];
  rows: ReviewRow[] = [];

  // 3行目以降は削除
  private getSummary(review: string = "", lineMax: number): string {
    const lines: string[] = review.split("\n");
    const summary = lines.splice(0, lineMax).join("\n"); // 最大行数
    if (summary.length > 42 * lineMax) {
      return summary.substring(0, 42 * lineMax) + "……"; // 最大文字数
    }
    return summary;
  }

  created(): void {
    this.poiGroupId = parseInt(this.$route.params.poiGroupId as string, 10);
  }
  async mounted(): Promise<void> {
    this.pageLoad();
  }

  async pageLoad(): Promise<void> {
    if (this.selectedTarget.isNone) {
      return;
    }
    if (this.selectedKeyword === "") {
      // ランキングにキーワード含まれてなかったらキーワードを含むクチコミをクリアする
      this.clearRows();
      return;
    }

    await this.fetchReviewSearch();
    this.makeReviewSearchRows();
  }

  async fetchReviewSearch(): Promise<void> {
    const params: ControllersReviewsSearchInput = {};
    params.startDate = dayjs(this.dateFrom).startOf("month").format("YYYY-MM-DD"); // 指定月の月初
    params.endDate = dayjs(this.dateTo).endOf("month").format("YYYY-MM-DD"); // 指定月の月末

    if (this.selectedTarget.isAll) {
      /* パラメータなし */
    } else if (this.selectedTarget.isArea && this.selectedTarget.areaId > -1) {
      params.areaID = this.selectedTarget.areaId;
    } else if (this.selectedTarget.poiIds.length > 0) {
      params.poiID = this.selectedTarget.poiIds[0];
    }

    // 返信あり, 返信なし
    params.replied =
      (this.hasReplied && this.hasNotReplied) || (!this.hasReplied && !this.hasNotReplied)
        ? null // 両方ともチェックなし or 両方ともチェックあり → すべてのクチコミ
        : this.hasReplied
        ? true // 返信ありのみチェック → 返信ありのみ
        : false; // 返信なしのみチェック → 返信なしのみ

    params.starRatings = this.hasStar.length > 0 ? this.hasStar.join(",") : ""; // 選択なしの場合はすべての星
    params.word = this.selectedKeyword; // キーワードはなしにできない
    params.offset = (this.page - 1) * this.perPage;
    params.limit = this.perPage;
    this.loading = true;
    await requiredAuth<DomainReviewsSearch>(
      "get",
      `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/reviews/search-reviews`,
      params,
      null,
      null,
      null,
      false
    )
      .then((res) => (this.reviewsSearch = res?.data))
      .catch((err) => console.error("クチコミ検索エラー", err))
      .finally(() => (this.loading = false));

    if (this.page === 1) {
      // 1ページ目以外はtotalCountが0になるのがAPIの仕様
      this.totalCount = this.reviewsSearch.totalCount;
    }
    this.pageCount = Math.ceil(this.totalCount / this.perPage);
  }

  makeReviewSearchRows(): void {
    let index = 0;
    this.rows.splice(0, this.rows.length);
    if (!this.reviewsSearch || this.reviewsSearch.reviews.length === 0) {
      return;
    }

    this.reviewsSearch.reviews.forEach((item: DomainReviewItem) => {
      this.rows.push({
        index: index++,
        createTime: dayjs(item.createTime).local().format("YYYY-MM-DD HH:mm"),
        updateTime: dayjs(item.updateTime).local().format("YYYY-MM-DD HH:mm"),
        storeName: item.storeName,
        starRating: item.starRating,
        review: item.review,
        reviewSummary: this.getSummary(item.review, 2),
        reply: item.reply,
        replySummary: this.getSummary(item.reply, 1),
      });
    });
  }

  /** クチコミ表示消す */
  clearRows(): void {
    this.rows = [];
    this.pageCount = 0;
  }

  pageChange(page: number): void {
    this.page = page;
    this.pageLoad();
  }

  checkThisStar(grade: number): boolean {
    const hasStar = this.hasStar.find((star) => star === grade);
    return hasStar !== undefined ? true : false;
  }
}
export default toNative(ReviewSearch);
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;
@use "@/components/style/font.scss" as font;

.review-container {
  margin: 0;
  padding-top: 10px;
}

.condition-header {
  display: flex;
  align-items: baseline;
  margin: 10px 0;
}

.condition {
  font: normal normal 12px/18px font.$font-family;
  margin-top: 3px;
  margin-right: 5px;
}

.ts-checkbox {
  display: inline-block;
  margin-left: 6px;
  padding: 6px 6px 6px 8px;
  line-height: 1.3em;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  & > input {
    display: none;
  }
  &.checked {
    border-color: color.$base-orange;
  }
  &:first-of-type,
  &.star1 {
    margin-left: 0;
  }
}

:deep(.v-label) {
  font: normal normal 12px/18px font.$font-family;
}

:deep(.v-input--selection-controls) {
  margin-top: 0;
  margin-right: 10px;
  padding-top: 0px;
}

:deep(.v-input--selection-controls__input) {
  margin: 0 !important;
}

.data-table-wrap {
  position: relative;
}

:deep(.review-search-table) {
  min-width: 300px;
  margin-bottom: 30px;
  border: 1px solid #d6d6d6;
  border-collapse: collapse;
  color: #333333;
  table {
    th {
      height: 28px;
      text-align: center !important;
      vertical-align: middle;
      font: normal medium 10px/15px font.$font-family;
      letter-spacing: 0px;
      color: #404040;
      background: #f6f6f6 0% 0% no-repeat padding-box;
      padding: 0;
    }

    th:nth-of-type(n + 2):nth-of-type(-n + 5) {
      border-right: thin dashed rgba(0, 0, 0, 0.12);
      border-left: thin dashed rgba(0, 0, 0, 0.12);
    }

    tr.v-data-table__selected {
      background-color: #ee9d2627 !important;
    }
    tr.v-data-table-rows-no-data {
      td {
        font-size: 16px;
      }
    }

    tr:hover {
      background-color: #ee9e2635 !important;
    }

    td {
      vertical-align: middle;
      height: 40px;
      font: normal normal 12px/18px font.$font-family;
      border-bottom: none !important;
      padding: 0 10px;
    }

    td:nth-of-type(n + 2):nth-of-type(-n + 5) {
      border-right: thin dashed rgba(0, 0, 0, 0.12);
      border-left: thin dashed rgba(0, 0, 0, 0.12);
    }

    .reply-arrow {
      margin: 0 5px;
      transform: rotate(90deg);
    }

    .detail-row {
      background: #f9f9f9 0% 0% no-repeat padding-box;
    }

    .detail-review {
      margin: 10px 0;
    }

    .detail-reply {
      margin-left: 9px;
      margin-bottom: 10px;
      padding: 5px 10px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #d6d6d6;
    }
  }
}

.progress-circular-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
