<template>
  <div>
    <v-card class="ma-3">
      <v-card-text><p class="text-h5">Yahoo!プレイス 連携設定</p></v-card-text>
    </v-card>
    <v-card class="ma-3" max-width="800">
      <v-card-text>
        <p class="text-h6 mb2">{{ appName }}とYahoo!プレイスの接続の管理</p>
        <br />
        <p class="text-body-1">接続作成者</p>
        <v-autocomplete
          v-model="uuid"
          class="selector"
          :items="accountPulldownItems"
          item-title="label"
          item-value="uuid"
          label="ユーザを選択してください"
          single-line
          hide-details
          @update:model-value="showConnection"
        />
      </v-card-text>
      <v-card-text>
        <ul v-if="selectedAccount">
          <li class="mb-2">
            <ul>
              <li>Yahoo!の識別子(sub): {{ selectedAccount.sub }}</li>
              <li>接続した日時: {{ selectedAccount?.updateTime }}</li>
            </ul>
          </li>
        </ul>
        <span v-else>まだ接続していません</span>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="uuid === user.uuID"
          variant="elevated"
          color="primary"
          @click="connectDialog.show = true"
        >
          <span v-if="!yahooAccount" class="text-capitalize">
            {{ appName }} と Yahoo!プレイス を接続する
          </span>
          <span v-else>接続をやり直す</span>
        </v-btn>
        <v-btn
          v-if="yahooAccount"
          variant="elevated"
          color="error"
          @click="disconnectDialog.show = true"
        >
          接続を解除する
        </v-btn>
      </v-card-actions>
    </v-card>
    <div class="d-flex flex-wrap ml-3">
      <v-btn
        :disabled="loading || exportLoading"
        class="primary"
        prepend-icon="mdi-download"
        text="XLSXエクスポート"
        @click="exportXlsx"
      />
      <input ref="importInput" type="file" hidden @change="importXlsx" />
      <v-btn
        :disabled="loading"
        class="white"
        prepend-icon="mdi-upload"
        text="XLSXインポート"
        @click="($refs.importInput as HTMLInputElement).click()"
      />
    </div>
    <v-card v-if="selectedAccount" class="ma-3">
      <v-card-text>
        <p class="text-h6 mb-2">{{ appName }}の店舗とYahoo!プレイスの店舗の紐づけの管理</p>
        <p class="text-body-2 mb-2">
          {{ getAccountName(uuid) }}
          の利用可能な店舗の一覧です。他の人が紐づけをしている場合でも表示されます。
        </p>
        <v-text-field
          v-model="search"
          label="Search (店舗ID, 店舗コード, 店舗名)"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          hide-details
          single-line
          density="compact"
          class="mb-3"
        />
        <v-data-table
          :items="stores"
          :headers="[
            { title: '店舗ID', key: 'poiID' },
            { title: '店舗コード', key: 'gmbStoreCode' },
            { title: '店舗名', key: 'name' },
            { title: 'Yahoo!プレイス側店舗名', key: 'yahooplace.placeSeq' },
            { title: '接続作成者', key: 'yahooplace.uuid' },
            { title: '編集/紐づけ解除', key: 'actions', sortable: false },
          ]"
          :search="search"
          density="compact"
          :items-per-page="100"
        >
          <template #item.yahooplace.placeSeq="{ item }">
            {{ getYahooStoreName(item.yahooplace) }}
          </template>
          <template #item.yahooplace.uuid="{ item }">
            {{ getAccountName(item.yahooplace?.uuid) }}
          </template>
          <template #item.actions="{ item }">
            <v-btn
              v-if="storeDialog.items.length > 0"
              size="x-small"
              icon="fas fa-pencil"
              @click="
                storeDialog.poiId = item.poiID;
                storeDialog.value = item.yahooplace?.placeSeq;
                storeDialog.show = true;
              "
            />
            <span v-if="item.yahooplace?.placeSeq ?? 0 !== 0">
              <v-btn
                size="x-small"
                icon="fas fa-plug-circle-xmark"
                @click="
                  unbindStoreDialog.poiId = item.poiID;
                  unbindStoreDialog.show = true;
                "
              />
              <!-- <v-btn size="x-small" icon="fas fa-plug-circle-xmark" @click="update(item.poiID)" /> -->
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>

  <!-- 接続ダイアログ -->
  <v-dialog v-model="connectDialog.show" width="677" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        Yahoo!プレイスへの接続
      </v-card-title>
      <v-card-text>
        下のログインボタンをクリックして、トストアアプリに対してYahoo!プレイスAPIへのアクセス権限を付与してください。
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mr-4" @click="connectDialog.show = false">キャンセル</v-btn>
        <div style="cursor: pointer" @click="gotoOauth()">
          <img src="@/assets/images/yahoo_login.png" alt="Yahoo! JAPAN IDでログイン" width="196" />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- 接続解除削除確認ダイアログ -->
  <v-dialog v-model="disconnectDialog.show" max-width="600">
    <confirm-dialog
      message="接続を解除しますがよろしいですか？"
      cancel-button="キャンセル"
      submit-button="接続を解除する"
      @cancel="disconnectDialog.show = false"
      @submit="disconnect"
    />
  </v-dialog>

  <!-- 店舗選択ダイアログ -->
  <v-dialog v-model="storeDialog.show" width="677" persistent class="uploading-dialog">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>店舗選択</v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="storeDialog.value"
          class="selector"
          clearable
          :items="filterBindedYahooPlaces"
          :multiple="false"
          item-title="title"
          item-value="id"
          label="Yahoo!プレイスの店舗を選択してください"
          single-line
          hide-details
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="storeDialog.show = false">キャンセル</v-btn>
        <v-btn depressed color="primary" :disabled="!storeDialog.value" @click="bindStore()">
          登録
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- 店舗紐づけ解除確認ダイアログ -->
  <v-dialog v-model="unbindStoreDialog.show" max-width="600">
    <confirm-dialog
      message="店舗の紐づけを解除しますがよろしいですか？"
      cancel-button="キャンセル"
      submit-button="紐づけを解除する"
      @cancel="unbindStoreDialog.show = false"
      @submit="unbindStore(unbindStoreDialog.poiId)"
    />
  </v-dialog>

  <!-- 結果ダイアログ -->
  <v-dialog v-model="resultDialog.show" max-width="500">
    <v-card>
      <v-card-title class="headline">XLSXインポート</v-card-title>
      <v-card-text>
        <!-- eslint-disable-next-line -->
        <div v-html="resultDialog.message" />
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" variant="text" @click="resultDialog.show = false">閉じる</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <div v-if="loading" class="progress-circular-container">
    <v-progress-circular :size="80" :width="4" color="primary" indeterminate></v-progress-circular>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ConfirmDialog from "@/components/root/contents/food-menu/confirm-dialog.vue";
import { yahooOauthClientId } from "@/const";
import { useIndexedDb } from "@/storepinia/idxdb";
import { currentTheme } from "@/components/shared/theme";
import type {
  EntitiesUser,
  EntitiesYahooAccount,
  EntitiesStore,
  EntitiesYahooPlaceBusiness,
} from "@/types/ls-api";
import { api as userApi } from "@/helpers/api/setup";
import { api as yapi } from "@/helpers/api/yahoo";
import { useSnackbar } from "@/storepinia/snackbar";
import { arrayBufferToStringsArrays, read } from "@/helpers/xlsxtools";
import pLimit from "p-limit";
import { isAxiosError } from "axios";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import wordDictionary from "@/word-dictionary";

interface AccountPulldownItem {
  uuid: string;
  label: string;
}
export default defineComponent({
  components: { ConfirmDialog },
  data: () => {
    return {
      person: 1,
      loading: false,
      exportLoading: false,
      company: useIndexedDb().company,
      uuid: null as string | null,
      yahooDialog: { show: false },
      appName: currentTheme().appName as string,
      user: useIndexedDb().user,
      isComUser: useIndexedDb().isComUser,
      canSelectFacebookAccount: useIndexedDb().canSelectFacebookAccount,
      accountPulldownItems: [] as AccountPulldownItem[],
      selectedAccount: null as EntitiesYahooAccount | null,
      yahooAccounts: [] as EntitiesYahooAccount[],
      stores: [] as EntitiesStore[],
      search: "",
      connectDialog: { show: false },
      disconnectDialog: { show: false },
      storeDialog: {
        show: false,
        poiId: 0,
        value: 0,
        items: [] as { id: number; title: string }[],
      },
      unbindStoreDialog: { show: false, poiId: 0 },
      resultDialog: { show: false, message: "" },
    };
  },
  computed: {
    poiGroupID: function (): number {
      return parseInt(this.$route.params.poiGroupId as string);
    },
    yahooAccount: function (): EntitiesYahooAccount {
      return this.yahooAccounts.find((account) => account.uuid === this.uuid);
    },
    filterBindedYahooPlaces: function (): { id: number; title: string }[] {
      return this.storeDialog.items.filter((ypb) => {
        return !this.stores.find(
          (store) =>
            store.yahooplace?.placeSeq === ypb.id &&
            store.yahooplace.placeSeq !== this.storeDialog.value
        );
      });
    },
  },
  async mounted() {
    await this.getYahooAccounts();
  },
  methods: {
    async gotoOauth() {
      const url = "https://auth.login.yahoo.co.jp/yconnect/v2/authorization";
      const params = {
        scope: "openid profile email",
        redirect_uri: `${import.meta.env.VITE_APP_API_BASE}v1/setup/oauth2/callback/yahoo`,
        state: `${this.poiGroupID} ${location.href} ${sessionStorage.IdToken}`,
        response_type: "code",
        client_id: yahooOauthClientId,
        prompt: "consent",
      };
      const oauthurl = url + "?" + new URLSearchParams(params).toString();
      location.href = oauthurl;
    },
    async disconnect() {
      if (this.loading) return; // 連打防止
      this.loading = true;
      try {
        await userApi.disconnectYahooAccount(this.poiGroupID, this.uuid);
      } catch (e: any) {
        console.error(e);
        useSnackbar().addSnackbarMessages({
          text: "接続解除に失敗しました",
          color: "danger",
        });
      }
      await this.getYahooAccounts();
      this.disconnectDialog.show = false;
    },
    async getYahooAccounts() {
      this.loading = true;
      try {
        const [accounts, users] = await Promise.all([
          userApi.getYahooAccounts(this.poiGroupID),
          this.fetchUsers(this.company.poiGroupID),
        ]);
        // 管理者用のuser情報を取得
        if (this.isComUser) {
          const adminUsers = await this.fetchUsers(0);
          users.push(...adminUsers);
        }
        const items: AccountPulldownItem[] = [];
        accounts.forEach((account) => {
          const user = users.find((user) => user.uuID === account.uuid);
          if (!user) return;
          items.push({
            uuid: account.uuid,
            label: `${user.familyName ?? ""} ${user.firstName ?? ""}`,
          });
        });
        // 自分が選択肢にない場合は追加する
        if (items.find((item) => item.uuid === this.user.uuID) === undefined) {
          items.unshift({
            uuid: this.user.uuID,
            label: `${this.user.familyName} ${this.user.firstName}`,
          });
        }
        items.find((item) => item.uuid === this.user.uuID).label += " (自分)";
        this.accountPulldownItems = items;
        this.yahooAccounts = accounts;
        this.uuid = this.user.uuID;
        this.showConnection();
      } finally {
        this.loading = false;
      }
    },
    async showConnection() {
      this.loading = true;
      try {
        // 選択されたユーザが利用可能な、GBPの店舗一覧を取得
        this.stores = (await yapi.listStores(this.poiGroupID, this.uuid)).filter(
          (store) => store.options?.includes("yahooplace") ?? false
        );
        // Yahoo!プレイスの店舗選択のダイアログの選択肢を削除
        this.storeDialog.items = [];
        // 選択されたユーザのYahooアカウント情報を取得
        this.selectedAccount = this.yahooAccounts.find((account) => account.uuid === this.uuid);
        // Yahooアカウントが存在する場合は、Yahoo!プレイスの店舗選択のダイアログの選択肢を作成
        if (this.selectedAccount) {
          const ypbs: EntitiesYahooPlaceBusiness[] = [];
          // 最大で10000件取得する
          const size = 50;
          for (let i = 0; i < 200; i++) {
            const res = await yapi.listPlaceBusiness(this.poiGroupID, this.uuid, size, i);
            if (res.statusCode !== 200) {
              useSnackbar().addSnackbarMessages({
                text: `店舗一覧の取得に失敗しました: ${res.error?.errorCode}, ${res.error?.reason}`,
                color: "danger",
              });
              return;
            }
            ypbs.push(...res.data.list);
            if (res.data.totalCount <= size * (i + 1)) {
              break;
            }
          }
          this.storeDialog.items = ypbs.map((ypb) => {
            return {
              id: ypb.placeSeq,
              title: ypb.businessName,
            };
          });
        }
      } finally {
        this.loading = false;
      }
    },
    getAccountName(uuid: string): string {
      if (!uuid) return "-";
      const item = this.accountPulldownItems.find((item) => item.uuid === uuid);
      return item ? item.label : "管理者アカウント";
    },
    getYahooStoreName(yahooplace: EntitiesStore["yahooplace"]): string {
      if (this.loading) return "-";
      if (!yahooplace) return "-";
      if (!yahooplace.uuid && yahooplace.placeSeq) return "(接続が切れてます)";
      if (!yahooplace.placeSeq) return "-";
      const ypb = this.storeDialog.items.find((ypb) => ypb.id === yahooplace.placeSeq);
      return ypb
        ? ypb.title
        : this.getAccountName(this.uuid) + "が表示可能なYahoo!プレイス側店舗名ではありません";
    },
    async bindStore() {
      const poiId = this.storeDialog.poiId;
      const s = await yapi.putStoreYahooplace(this.$route, this.poiGroupID, poiId, {
        uuid: this.uuid,
        placeSeq: this.storeDialog.value,
      });
      this.stores
        .filter((store) => store.poiID === poiId)
        .forEach((store) => {
          store.yahooplace = s.yahooplace;
        });
      this.storeDialog.show = false;
    },
    async unbindStore(poiId: number) {
      const s = await yapi.putStoreYahooplace(this.$route, this.poiGroupID, poiId, {});
      this.stores
        .filter((store) => store.poiID === poiId)
        .forEach((store) => {
          store.yahooplace = s.yahooplace;
        });
      this.unbindStoreDialog.show = false;
    },
    async fetchUsers(poiGroupID: number): Promise<EntitiesUser[]> {
      if (!this.canSelectFacebookAccount) {
        // アカウント選択の権限がないユーザは自分のみを返す
        return [this.user];
      }
      return userApi.getUsers(poiGroupID);
    },
    // async update(poiId: number) {
    //   await yapi.patchStore(this.poiGroupID, poiId, { lineOfficialAccount: "connectom" + poiId }); // 値はテキトーです
    // },

    async isPlaceSeqDuplicated(
      placeSeq: number,
      data: { poiID: number; placeSeq: number }[]
    ): Promise<boolean> {
      const placeSeqCount = data.reduce((acc, record) => {
        if (record.placeSeq === placeSeq) {
          acc[record.placeSeq] = (acc[record.placeSeq] || 0) + 1;
        }
        return acc;
      }, {} as Record<number, number>);
      return Object.values(placeSeqCount).some((count) => count > 1);
    },
    async isPlaceSeqConflictForAssigment(
      placeSeq: number,
      poiIDAndPlaceSeqInStores: { poiID: number; placeSeq: number }[],
      poiIDAndPlaceSeqInExcel: { poiID: number; placeSeq: number }[]
    ): Promise<boolean> {
      const recordInStores = poiIDAndPlaceSeqInStores.find(
        (record) => record.placeSeq === placeSeq
      );
      // 他店舗に設定されていなければ重複していないと判断
      if (!recordInStores) {
        return false;
      }
      // Stores内のrecordInStoresのpoiIDがExcel内に存在しない場合は重複とみなす
      const recordExistsInExcel = poiIDAndPlaceSeqInExcel.some(
        (record) => record.poiID === recordInStores.poiID
      );
      if (!recordExistsInExcel) {
        return true;
      }
      // 同じpoiIDで異なるplaceSeqがExcelに存在する場合は付け替えとみなして重複ではない
      const isDifferentPlaceSeq = poiIDAndPlaceSeqInExcel.some(
        (record) => record.poiID === recordInStores.poiID && record.placeSeq !== placeSeq
      );
      return !isDifferentPlaceSeq;
    },
    async importXlsx(event: Event) {
      const errorRows: string[] = [];
      let successCount = 0;

      // 店舗一覧XLSXを読み込んで、店舗の紐づけを更新する
      try {
        this.loading = true;
        const target = event.target as HTMLInputElement;
        const xlsxFile: File = target.files[0];
        if (!xlsxFile) {
          console.error("XLSXファイルが選択されていません。");
          return;
        }
        const buf = await read(xlsxFile as any);
        const book = arrayBufferToStringsArrays(buf);
        const sheet = book[0];
        const header = sheet[0];
        // ヘッダ行をチェックして想定外ならエラートーストを表示
        const validHeaders = [
          ["店舗ID", "店舗コード", "店舗名", "Yahoo!プレイス施設シーケンスキー(placeSeq)"].join(
            ","
          ),
          [
            "店舗ID",
            "店舗コード",
            "店舗名",
            "Yahoo!プレイス施設シーケンスキー(placeSeq)",
            "Yahoo!プレイス側店舗名",
          ].join(","),
        ];
        if (!validHeaders.includes(header.join(","))) {
          useSnackbar().addSnackbarMessages({
            text: "想定外の形式。本画面でエクスポートしたXLSXファイルを使ってください。",
            color: "warning",
          });
          return;
        }

        const poiIDAndPlaceSeqInExcel = sheet.slice(1).map((row) => {
          return {
            poiID: Number(row[0]),
            placeSeq: Number(row[3]),
          };
        });

        const poiIDAndPlaceSeqInStores = this.stores.map((store) => {
          return {
            poiID: store.poiID,
            placeSeq: store.yahooplace?.placeSeq,
          };
        });

        const stores = this.stores;
        const limit = pLimit(5); // 最大同時処理数を設定
        const tasks = sheet.slice(1).map((row, i) => {
          const index = i + 2; // slice(1)でヘッダ行を飛ばしたので+2
          return limit(async () => {
            const poiID = row[0];
            const store = stores.find((s) => s.poiID.toString() === poiID);
            if (!store) {
              errorRows.push(`<b>${index}行目</b> 店舗ID ${poiID} が見つかりません。`);
              return;
            }

            // "-"は未設定扱いとする
            let placeSeq: number;
            if (row[3] == "-") {
              placeSeq = 0;
            } else {
              placeSeq = Number(row[3]);
            }

            if (isNaN(placeSeq)) {
              errorRows.push(
                `<b>${index}行目</b> Yahoo!プレイス施設シーケンスキー ${row[3]} は有効な値ではありません。`
              );
              return;
            }
            if (placeSeq != 0) {
              const yahooPlace = this.storeDialog.items.find((ypb) => ypb.id === placeSeq);
              if (yahooPlace === undefined) {
                errorRows.push(
                  `<b>${index}行目</b> Yahoo!プレイス施設シーケンスキー ${placeSeq} が存在しません。`
                );
                return;
              }
            }
            // Excel内で重複している場合はエラー
            if (
              placeSeq != 0 &&
              (await this.isPlaceSeqDuplicated(placeSeq, poiIDAndPlaceSeqInExcel))
            ) {
              errorRows.push(
                `<b>${index}行目</b> Yahoo!プレイス施設シーケンスキー ${placeSeq} がExcel内で重複しています。`
              );
              return;
            }
            if (
              (store.yahooplace?.placeSeq == undefined && placeSeq == 0) ||
              store.yahooplace?.placeSeq == placeSeq
            ) {
              return;
            }
            if (
              placeSeq != 0 &&
              (await this.isPlaceSeqConflictForAssigment(
                placeSeq,
                poiIDAndPlaceSeqInStores,
                poiIDAndPlaceSeqInExcel
              ))
            ) {
              errorRows.push(
                `<b>${index}行目</b> Yahoo!プレイス施設シーケンスキー ${placeSeq} は既に他の店舗に設定されています。`
              );
              return;
            }
            try {
              await yapi.putStoreYahooplace(this.$route, this.poiGroupID, Number(poiID), {
                uuid: placeSeq != 0 ? this.uuid : null,
                placeSeq: placeSeq,
              });
              successCount++;
              store.yahooplace = { placeSeq, uuid: placeSeq != 0 ? this.uuid : null };
            } catch (e: any) {
              errorRows.push(
                `<b>${index}行目</b> 店舗ID ${poiID} の店舗の紐づけに失敗しました。${this.message(
                  e
                )}`
              );
            }
          });
        });

        // 全てのタスクが完了するのを待機
        await Promise.all(tasks);
      } catch (e: any) {
        useSnackbar().addSnackbarMessages({
          text: `店舗一覧のXLSXインポート、店舗の紐づけの更新に失敗しました。${this.message(e)}`,
          color: "danger",
        });
      } finally {
        this.loading = false;

        this.resultDialog.message = `${successCount}件の店舗の紐づけを更新しました。`;
        if (errorRows.length > 0) {
          this.resultDialog.message +=
            `<br><br><b>${errorRows.length}件の店舗をエラーでスキップしました。</b><br>` +
            errorRows.join("<br>");
        }
        this.resultDialog.show = true;
      }
    },
    async exportXlsx(): Promise<void> {
      try {
        this.exportLoading = true;
        // トストア店舗一覧をXLSXファイルに出力
        const stores = this.stores.map((store) => {
          return {
            店舗ID: store.poiID,
            店舗コード: store.gmbStoreCode,
            店舗名: store.name,
            "Yahoo!プレイス施設シーケンスキー(placeSeq)": store.yahooplace?.placeSeq ?? "-",
            "Yahoo!プレイス側店舗名": this.getYahooStoreName(store.yahooplace),
          };
        });
        const wsStores = XLSX.utils.json_to_sheet(stores);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, wsStores, "店舗紐づけ一覧");

        // Yahoo!プレイス一覧をXLSXファイルに出力
        const yahooPlaces = this.storeDialog.items.map((yahooPlace) => {
          return {
            "Yahoo!プレイス施設シーケンスキー(placeSeq)": yahooPlace.id,
            "Yahoo!プレイス側店舗名": yahooPlace.title,
          };
        });
        const wsLocations = XLSX.utils.json_to_sheet(yahooPlaces);
        XLSX.utils.book_append_sheet(wb, wsLocations, "Yahoo!プレイス側店舗一覧");

        const companyName = useIndexedDb().company.name;
        const datetime = dayjs().format("YYYYMMDD");
        const fileName = `${wordDictionary.citationSettingsYahoo.name}-店舗一覧エクスポート-${companyName}-${datetime}.xlsx`;
        XLSX.writeFile(wb, fileName);
      } catch (e: any) {
        useSnackbar().addSnackbarMessages({
          text: `店舗一覧のXLSXエクスポートに失敗しました。 ${this.message(e)}`,
          color: "danger",
        });
      } finally {
        this.exportLoading = false;
      }
    },
    message(error: Error): string {
      console.error(error);
      if (isAxiosError(error)) {
        return error.response?.data?.errorMessage ?? error.message ?? "不明なメッセージ";
      }
      return error.message;
    },
  },
});
</script>

<style lang="scss" scoped>
div.pagination {
  ul.pagination-list {
    margin: 0;
    list-style: none;
  }
}
.content li + li {
  margin-top: 0 !important;
}
.modal.is-active {
  display: flex;
  z-index: var(--z-index-modal);
}
.error-message {
  color: red;
}
.iconPosition {
  padding-top: 5px;
  padding-left: 10px;
}
.buttonSection {
  text-align: right;
}
.control-display-checkbox {
  width: 220px;
  margin-left: auto;
  text-align: right;
  margin-right: 10px;
}
</style>
