<template>
  <div class="review-container">
    <v-data-table
      :headers="headers"
      :items="rows"
      :items-per-page="10"
      class="keyword-ranking-table good"
      :loading="loading"
      @update:sort-by="onSort"
    >
      <template #body="{ items }">
        <tr
          v-for="item of items"
          :key="item.rank"
          :class="{ highlight: selectedKeyword === item.name }"
          @click="clickItem(item)"
        >
          <td class="rank">{{ item.rank }}</td>
          <td class="keyword">{{ item.name }}</td>
          <td class="count">
            {{ item.totalCount.toLocaleString() }}
          </td>
          <td class="count">{{ item.positiveCount }}</td>
          <td class="count">{{ item.negativeCount }}</td>
          <td class="ratio">
            <v-tooltip :text="balloonText" content-class="ratio-bar-balloon" location="top">
              <template #activator="{ props }">
                <div v-bind="props" class="ratio-bar">
                  <div
                    class="ratio-bar-positive"
                    :style="`width: ${item.goodPercent}%`"
                    @mouseover="updateBalloon($event, 'p', item.goodPercent)"
                  ></div>
                  <div
                    class="ratio-bar-negative"
                    @mouseover="updateBalloon($event, 'n', item.badPercent)"
                  ></div>
                </div>
              </template>
              <template #default>
                <div class="balloon-tip">
                  <label content-class="balloon">{{ balloonText }}</label>
                </div>
              </template>
            </v-tooltip>
          </td>
        </tr>
        <tr v-if="items.length === 0">
          <td colspan="3" class="no-data">指定された条件にヒットするデータなし</td>
        </tr>
      </template>
      <template #bottom />
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, toNative } from "vue-facing-decorator";
import type { VDataTable } from "vuetify/components/VDataTable";
import { requiredAuth } from "@/helpers";
import type { SelectedTarget } from "@/components/shared/store-selector.vue";
import dayjs from "dayjs";
import type { DomainReviewSentimentsStatisticsRecord } from "@/types/ls-api";

type Header = Pick<VDataTable["headers"][number], "key" | "title" | "align" | "width" | "sortable">;

export interface KeywordRankingRow extends DomainReviewSentimentsStatisticsRecord {
  rank: number;
  goodPercent: number;
  badPercent: number;
  isSelected: boolean;
}

export type KeywordInput = {
  areaID?: number;
  poiID?: number;
  startDate?: string;
  endDate?: string;
  sortBy?: string;
  order?: string;
  limit?: number;
};

@Component({
  emits: ["selectRankingRow"],
})
class ReviewKeywordRanking extends Vue {
  [x: string]: any;
  @Prop({ type: String }) dateTo: string;
  @Prop({ type: String }) dateFrom: string;
  @Prop({ type: Object }) selectedTarget!: SelectedTarget;
  @Prop({ type: String }) pickedKeyword: string;

  poiGroupId: number;
  keywordRanking: DomainReviewSentimentsStatisticsRecord[] = null;
  loading = false;
  selectedKeyword: string = "";
  balloonVisible = true;
  balloonText = "";

  headers: Header[] = [
    { title: "順位", key: "rank", align: "center", sortable: false, width: "5em" },
    { title: "キーワード", key: "name", align: "center", sortable: false, width: "20%" },
    { title: "総出現数", key: "totalCount", align: "center", sortable: true },
    {
      title: "ポジティブ出現数",
      key: "positiveCount",
      align: "center",
      sortable: true,
    },
    {
      title: "ネガティブ出現数",
      key: "negativeCount",
      align: "center",
      sortable: true,
    },
    { title: "ポジネガ比率", key: "goodRate", align: "center", sortable: true, width: "20%" },
  ];

  rows: KeywordRankingRow[] = [];

  created(): void {
    this.poiGroupId = parseInt(this.$route.params.poiGroupId as string, 10);
  }

  async pageLoad(): Promise<void> {
    this.selectedKeyword = "";
    await this.fetchRankingData();
    this.makeRankingData();
    // バブルチャートを更新
    this.$watch("options.handler", this.onSort, { deep: true });
  }

  async onSort(evt: string | any[]): Promise<void> {
    await this.fetchRankingData({
      sortBy: evt.length > 0 ? evt[0].key : null,
      order: evt.length > 0 ? evt[0].order : "desc",
    });
    this.makeRankingData();
  }

  async fetchRankingData(params?: KeywordInput): Promise<void> {
    if (this.selectedTarget.isNone) {
      return;
    }

    if (!params) {
      params = {};
    }

    params.startDate = dayjs(this.dateFrom, "YYYY-MM").startOf("month").format("YYYY-MM-DD"); // 指定月の月初,
    params.endDate = dayjs(this.dateTo, "YYYY-MM").endOf("month").format("YYYY-MM-DD"); // 指定月の月末

    if (this.selectedTarget.isAll) {
      /* パラメータなし */
    } else if (this.selectedTarget.isArea && this.selectedTarget.areaId > -1) {
      params.areaID = this.selectedTarget.areaId;
    } else if (this.selectedTarget.poiIds.length > 0) {
      params.poiID = this.selectedTarget.poiIds[0];
    }

    this.loading = true;
    await requiredAuth<DomainReviewSentimentsStatisticsRecord>(
      "get",
      `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/reviews/sentiments/stat`,
      params
    )
      .then((res) => {
        this.keywordRanking = res?.data as DomainReviewSentimentsStatisticsRecord[];
      })
      .finally(() => {
        this.loading = false;
      });
  }

  makeRankingData(): void {
    let hasKeyword = false;
    let rank = 1;
    this.rows.splice(0, this.rows.length);
    this.keywordRanking.map((item: DomainReviewSentimentsStatisticsRecord) => {
      // 取得したランキングに選択済みキーワードが含まれているかチェック
      hasKeyword = item.name === this.pickedKeyword ? true : hasKeyword;
      const goodPercent = Math.round(item.goodCountRatio * 10000) / 100;
      const badPercent = Math.round(10000 - item.goodCountRatio * 10000) / 100;
      this.rows.push({
        rank: rank++,
        name: item.name,
        totalCount: item.totalCount,
        positiveCount: item.positiveCount,
        negativeCount: item.negativeCount,
        goodPercent,
        badPercent,
        isSelected: false,
      });
    });

    if (this.pickedKeyword !== "" && hasKeyword === false) {
      // ランキングにキーワード無かったらランキングの選択をクリアする為にclickItemを呼び出す
      const fakeRow: KeywordRankingRow = {
        rank: -1,
        name: "",
        totalCount: 0,
        positiveCount: 0,
        negativeCount: 0,
        goodPercent: 0,
        badPercent: 0,
        isSelected: true,
      };
      this.clickItem(fakeRow);
    }
  }

  // 「キーワードを含むクチコミ」の選択状態を更新
  clickItem(item: KeywordRankingRow): void {
    this.selectedKeyword = item.name;
    this.$emit("selectRankingRow", item.isSelected ? { keyword: "" } : item);
  }

  updateBalloon(e: MouseEvent, type: "p" | "n", val: number): void {
    const labelStr = type === "p" ? "ポジティブ" : "ネガティブ";
    this.balloonText = `${labelStr}比率 : ${val}%`;
  }

  // ポジネガ比率のツールチップをマウスオーバーで表示・非表示
  showBalloon(e: MouseEvent, show: boolean): void {
    this.balloonVisible = show;
    if (show) {
      this.$nextTick(() => {
        const balloon: HTMLDivElement = document.getElementsByClassName(
          "balloon-tip"
        )[0] as HTMLDivElement;
        balloon.style.left = e.pageX + "px";
        const bounds: DOMRect = (e.target as HTMLElement).getBoundingClientRect();
        balloon.style.left =
          bounds.x + document.documentElement.scrollLeft + bounds.width / 2 - 91 + "px";
        balloon.style.top = bounds.y + document.documentElement.scrollTop - 31 + "px";
      });
    }
  }
}
export default toNative(ReviewKeywordRanking);
</script>
<style lang="scss" scoped>
.review-container {
  margin: 20px 20px 0 0;
}

%arrow {
  background-position: calc(100% - 5px) center;
  background-repeat: no-repeat;
  background-size: 8px 12px;
}

:deep(.keyword-ranking-table) {
  min-width: 300px;
  border-width: 1px 0;
  border-style: solid;
  border-color: #d6d6d6;
  border-collapse: collapse;
  border-radius: 0;
  table {
    th {
      position: relative;
      height: 0 !important;
      text-align: center !important;
      vertical-align: middle;
      font: normal normal bold 14px/18px sans-serif;
      letter-spacing: 0px;
      color: #404040;
      background: #f6f6f6 0% 0% no-repeat padding-box;
      padding: 5px 0 !important;
      border-style: solid;
      border-color: #d6d6d6;
      border-width: 0 1px;
      i {
        display: none;
      }
      &:not(.v-data-table__th--sorted).v-data-table__th--sortable {
        // TM-6940 デザイン再現の為に画像でやる必要がある
        background-image: url("@/assets/images/arrow_up_down.svg");
        background-position: calc(100% - 5px) center;
        background-repeat: no-repeat;
      }
      &.v-data-table__th--sorted:has(i.mdi-arrow-up) {
        // TM-6940 デザイン再現の為に画像でやる必要がある
        background-image: url("@/assets/images/arrow_up_active.svg");
        @extend %arrow;
      }

      &.v-data-table__th--sorted:has(i.mdi-arrow-down) {
        // TM-6940 デザイン再現の為に画像でやる必要がある
        background-image: url("@/assets/images/arrow_down_active.svg");
        @extend %arrow;
      }
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }

    td {
      height: 0 !important;
      padding: 5px 15px !important;
      vertical-align: middle;
      font: normal normal 14px/20px sans-serif;
      border-bottom: none !important;
      border-style: solid;
      border-color: #d6d6d6;
      border-width: 0 thin;
    }

    tbody {
      td:first-of-type {
        background-color: transparent !important;
      }

      tr.highlight {
        background-color: #ee9d2627 !important;
      }

      tr:hover {
        background-color: #ee9e2635 !important;
        cursor: pointer;
      }

      td {
        &:first-of-type {
          border-right-style: none;
        }
        &:nth-of-type(2) {
          border-style: dashed;
        }
        &:nth-of-type(3) {
          border-left-style: none;
        }
      }

      tr:nth-child(1),
      tr:nth-child(2),
      tr:nth-child(3) {
        td:nth-of-type(2) {
          font: normal bold 20px/29px sans-serif;
        }
      }
    }
  }
}

.rank {
  text-align: center;
}
.count {
  text-align: right;
}

:deep(.keyword-ranking-table.good) {
  table {
    /* 2列目のスタイル */
    td:nth-of-type(2) {
      color: #68cbb6;
      font-weight: 800;
    }
  }
}

.v-tooltip__content {
  background: #fff;
  opacity: 1;
  color: #000;
  box-shadow: 0px 3px 6px #00000029;
}

.center-labels {
  width: 200px;
  height: 200px;
  top: 0px;
  text-align: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.count-label {
  font-size: 25px;
  font-weight: bold;
  margin: 0 0 5px 0;
  padding: 10px 10px;
  border-bottom: solid rgba(0, 0, 0, 0.12);
}
.total-label {
  margin: 0;
  font-size: 14px;
}
.total-description {
  margin: 0;
  font-size: 13px;
}
.no-data {
  margin: 10px 20px;
  color: rgba(0, 0, 0, 0.38);
  font: normal normal 14px/20px sans-serif;
}
.ratio {
  padding: 15px 20px !important;
}
.ratio-bar {
  width: 100%;
  height: 30px;
  position: relative;
}
.ratio-bar-positive {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #66cbb6;
  height: 100%;
}

.ratio-bar-negative {
  background-color: #66cbb6;
  height: 100%;
  background-color: #f17573;
}

.balloon-tip {
  width: 182px;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  max-height: 30px;
  line-height: 30px;
  color: #000000;
  background-color: #ebedf0;
  box-shadow: 0px 0px 2px 0px #000000;
  opacity: 1 !important;

  label {
    display: block;
    text-align: center;
    padding-bottom: 34px;
    // TM-6940 デザイン再現の為に画像でやる必要がある
    background-image: url("@/assets/images/gray_fill.png"), url("@/assets/images/fukidashi.png");
    background-repeat: no-repeat;
    background-position: center calc(100% - 31px), center calc(100% - 17px);
    background-size: 29px 34px, 29px 34px;
  }
}
</style>
<style lang="scss">
// キーワードポジネガ分析のツールチップのmarginに、背景色が設定されてしまうのに対処。scopedではなく全体設定の必要がある
.v-tooltip > .v-overlay__content.ratio-bar-balloon {
  margin: 0;
  padding: 0;
}
</style>
