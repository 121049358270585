<template>
  <div>
    <v-tabs v-model="performanceTab" color="primary" height="100%" grow>
      <v-tab value="impression">
        <performance-summary-tab
          :is-impression="true"
          :performance-summary="performanceSummary"
          :is-loading="isSummaryLoading"
        />
      </v-tab>
      <v-tab value="reaction">
        <performance-summary-tab
          :is-impression="false"
          :performance-summary="performanceSummary"
          :is-loading="isSummaryLoading"
        />
      </v-tab>
    </v-tabs>
    <v-window v-model="performanceTab">
      <v-window-item value="impression" @group:selected="onTabChange">
        <v-card class="tabItem">
          <v-card-title class="report-title">表示回数推移（月別）</v-card-title>
          <performance-summary-trend
            ref="impression"
            :is-impression="true"
            :performance-trend="performanceTrend"
            :date-params="dateParams"
            :is-loading="isTrendLoading"
          />
        </v-card>
      </v-window-item>
      <v-window-item value="reaction" @group:selected="onTabChange">
        <v-card class="tabItem">
          <v-card-title class="report-title">反応数推移（月別）</v-card-title>
          <performance-summary-trend
            ref="reaction"
            :is-impression="false"
            :performance-trend="performanceTrend"
            :date-params="dateParams"
            :is-loading="isTrendLoading"
          />
        </v-card>
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PerformanceSummaryTab from "./performance-2-2-summary-tab.vue";
import PerformanceSummaryTrend from "./performance-2-3-summary-trend.vue";
import type {
  DomainPerformanceSummaryResponse,
  DomainPerformanceInsightResponse,
} from "@/types/ls-api";
import { requiredAuth } from "@/helpers";
import type { DateParams, StoreFilter } from "./performance";

export default defineComponent({
  components: { PerformanceSummaryTab, PerformanceSummaryTrend },
  props: {
    isImpression: { type: Boolean, default: true }, // true:表示回数, false:反応数
    dateParams: { type: Object as () => DateParams, required: true },
    storeFilter: {
      type: Object as () => StoreFilter,
      required: false,
      default: () => ({} as StoreFilter),
    },
  },
  data() {
    return {
      performanceSummary: getDefaultPerformanceSummary(),
      performanceTrend: getDefaultPerformanceTrend(),
      isSummaryLoading: true,
      isTrendLoading: true,
      performanceTab: "impression",
    };
  },
  computed: {
    poiGroupID: function (): number {
      return parseInt(this.$route.params.poiGroupId as string);
    },
  },
  watch: {},
  created() {},
  methods: {
    async fetch(): Promise<void> {
      await Promise.all([this.fetchSummary(), this.fetchTrend()]);
    },
    async fetchSummary(): Promise<void> {
      try {
        this.isSummaryLoading = true;

        const params = Object.assign({}, this.storeFilter, this.dateParams);
        const result = await requiredAuth<DomainPerformanceSummaryResponse>(
          "get",
          `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupID}/performance/summary`,
          params
        );
        this.performanceSummary = result?.data ?? getDefaultPerformanceSummary();
      } catch (e) {
        console.error("fetch exception catch", e);
      } finally {
        this.isSummaryLoading = false;
      }
    },
    async fetchTrend(): Promise<void> {
      try {
        this.isTrendLoading = true;
        const result = await requiredAuth<DomainPerformanceInsightResponse>(
          "get",
          `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupID}/performance/insight`,
          this.storeFilter
        );
        this.performanceTrend = result?.data ?? getDefaultPerformanceTrend();
      } catch (e) {
        console.error("fetch exception catch", e);
      } finally {
        this.isTrendLoading = false;
      }
    },
    onTabChange(): void {
      // タブを切り替えたらグラフを再描画する(表示期間枠ずれ対策)
      this.$nextTick(() => {
        (
          this.$refs[this.performanceTab] as InstanceType<typeof PerformanceSummaryTrend>
        ).createStackedViewChart();
      });
    },
  },
});
function getDefaultPerformanceSummary(): DomainPerformanceSummaryResponse {
  const zero = () => ({
    business_impressions_maps: 0,
    business_impressions_search: 0,
    business_direction_requests: 0,
    call_clicks: 0,
    website_clicks: 0,
  });
  return { current: zero(), previous: zero() };
}
function getDefaultPerformanceTrend(): DomainPerformanceInsightResponse {
  return { seriesList: [] };
}
</script>
<style lang="scss" scoped>
:deep(.v-tabs-bar) {
  height: 440px;
}
:deep(.v-tab) {
  max-width: none;
  width: auto;
  text-align: left;
}
:deep(.v-tab) {
  border-bottom: solid;
  border-bottom-color: var(--primary);
  padding: 0 0 0 0;
}
:deep(.v-tab--selected) {
  border-width: 2px;
  border-top: 0px;
  border-left: solid;
  border-right: solid;
  border-bottom: none;
}
:deep(.v-tab__slider) {
  top: 0;
  height: 6px !important;
}
.common-title {
  /* Figmaから共通部分を抽出 */
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: #333333 !important;
}
.report-title {
  @extend .common-title;
  font-size: 24px;
  padding: 16px;
}
.tabItem {
  width: 100%;
  border-right: solid;
  border-left: solid;
  border-bottom: solid;
  padding: 0px;
  border-color: var(--primary);
  border-radius: 0;
}
</style>
