import type { AxiosError } from "axios";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { requiredAuth } from "@/helpers";
import { Component, Vue, toNative } from "vue-facing-decorator";
import wordDictionary from "@/word-dictionary";
import type { EntitiesUser, EntitiesRole } from "@/types/ls-api";
import { useSnackbar } from "@/storepinia/snackbar";
import { getter } from "@/storepinia/idxdb";
import { useDialog } from "@/storepinia/dialog";
import { currentTheme } from "@/components/shared/theme";
import type { ListGrid } from "cheetah-grid";
import type { CustomSortState } from "@/components/shared/cheetah-grid-shared";
import { arrayBufferToStringsArrays, read } from "@/helpers/xlsxtools";
import { api as uapi } from "@/helpers/api/user";
import { uniq } from "@/helpers/arrays";
import { makeImportUserData, diffUsers, makeXlsxData } from "./import-user";
import { makeSelectItems } from "@/helpers/select-items";
import ProgressDialog from "@/components/root/contents/media-link/progress-dialog.vue";

type DefaultBgColorArgs = {
    row: number;
    col: number;
    grid: ListGrid<UserView>;
};

class UserView {
    constructor(
        myself: EntitiesUser,
        user: EntitiesUser,
        groups: { [groupId: string]: string },
        storesMap: { [poiId: string]: string },
        role: EntitiesRole
    ) {
        this.myself = myself;
        this.user = user;
        this.groups = groups;
        this.storesMap = storesMap;
        this.role = role;
    }

    private myself: EntitiesUser;
    private user: EntitiesUser;
    private groups: { [areaId: string]: string };
    private storesMap: { [poiId: string]: string };
    private role: EntitiesRole;

    get uuid(): string {
        return this.user.uuID;
    }
    get firstName(): string {
        return this.user.firstName;
    }
    get familyName(): string {
        return this.user.familyName;
    }
    get email(): string {
        return this.user.mailAddress;
    }
    get group(): string {
        return this.user.areas.map((areaId) => this.groups[areaId.toString()]).join(", ");
    }
    get store(): string {
        return this.user.stores.map((poiId) => this.storesMap[poiId.toString()]).join(", ");
    }
    get roleLv(): number {
        return this.user.roleLv;
    }
    get viewRange(): number {
        return this.role.viewRange;
    }
    get roleName(): string {
        return this.role?.caption;
    }
    get mfaEnabled(): boolean {
        return this.user.useMFA;
    }
    get editCaption(): string {
        return wordDictionary.users.edit.title;
    }
    get deleteCaption(): string {
        if (this.myself.uuID === this.user.uuID) {
            return "";
        }
        return wordDictionary.users.deleteButtonName;
    }
}

@Component({ components: { ProgressDialog } })
class Users extends Vue {
    poiGroupId: string;
    isAdmin: boolean = false;
    loading: boolean = false;
    company = getter().company;
    user = getter().user;
    roleList = getter().roleList;
    areas = getter().areas;
    areaStores = getter().areaStores;
    stores = getter().stores;
    addSnackbarMessages = useSnackbar().addSnackbarMessages;

    // APIから取得した全てのユーザー情報
    users: UserView[] = [];
    // フィルターを適用した後のユーザー情報
    userRecord: UserView[] = [];

    dict = wordDictionary.users;

    // cheetah-gridのカスタムテーマ設定
    customTheme = {
        checkbox: {
            borderColor: currentTheme().vuetifyTheme.colors.primary,
        },
        defaultBgColor({ col, row, grid }: DefaultBgColorArgs): string {
            if (col < grid.frozenColCount || row < grid.frozenRowCount) {
                return "#f0f0f0";
            }
            return "#ffffff";
        },
        button: {
            color: currentTheme().vuetifyTheme.colors.primaryInvert,
            bgColor: currentTheme().vuetifyTheme.colors.primary,
        },
    };
    gridFontSize = 12.8;
    gridFont = `${this.gridFontSize}px sans-serif`;
    searchWord = "";
    initialized: boolean = false;
    // インポート確認ダイアログ
    confirmDialog = {
        show: false,
        inserts: [] as EntitiesUser[],
        updates: [] as EntitiesUser[],
        updateDiffs: [] as { mail: string; diffs: ReturnType<typeof diffUsers> }[],
    };
    // インポートダイアログ
    importDialog = {
        show: false,
        message: "",
        percentage: 0,
        submitButtonDisabled: false,
    };

    // 直前に行ったソート順と対象列の保存
    private customSortState: CustomSortState = {
        col: 0,
        order: null,
    };
    // cheetah-gridヘッダ部をクリックされたイベント
    sortColumn(order: number, col: number, grid: ListGrid<UserView>): void {
        if (col !== this.customSortState.col) {
            // 直前のと違う列をソートしようとした場合はorderを初期化する
            this.customSortState.order = "desc";
            this.customSortState.col = col;
        } else {
            switch (this.customSortState.order) {
                case null:
                    this.customSortState.order = "desc";
                    break;
                case "desc":
                    this.customSortState.order = "asc";
                    break;
                case "asc":
                    this.customSortState.order = null;
            }
        }
        // 実際にデータをソートする
        this.sortRecord(col, this.customSortState.order);
        // ↑↓マーク制御
        grid.sortState.order = this.customSortState.order;
    }
    // クリックされたカラムでソート処理を実行
    private sortRecord(col: number, order: string | null = "asc"): void {
        let orderVal: number = 1;
        // カラムの並び順
        const keys: string[] = [
            "email",
            "firstName",
            "familyName",
            "group",
            "viewRange", // ロール名でソートしても分かりづらいのでviewRangeでソートする
        ];
        let key = keys[col];
        if (order === null) {
            // 昇りでも降りでもない場合は初期の並び順であるemailの昇り順でのソートになる
            key = keys[0];
        } else if (order === "asc") {
            orderVal = -1;
        }
        this.users = this.users.sort((a, b) => {
            const aDash: string | number | boolean = a[key];
            const bDash: string | number | boolean = b[key];
            if (aDash > bDash) {
                return 1 * orderVal;
            } else {
                return -1 * orderVal;
            }
        });
        this.searchUsers();
    }
    searchUsers(): void {
        const userRecords: UserView[] | null = this.filterBySearchWord(this.users, this.searchWord);
        if (userRecords === null) {
            this.addSnackbarMessages({
                text: "不正な検索キーワードです",
                color: "danger",
            });
            return;
        }
        this.userRecord = userRecords;
        // この変数でcheetah-gridの遅延レンダリングを行っている
        // 遅延させないと、左のメニュー領域分の計算が狂ってスクロールバーが表示されてしまう
        this.initialized = true;
    }
    filterBySearchWord(allUsers: UserView[], searchWord: string): UserView[] | null {
        const rawUserRecords = allUsers;
        let records: UserView[] = [];
        const trimmedSearchWord = searchWord?.trim().toLowerCase();
        if (trimmedSearchWord && trimmedSearchWord !== "") {
            records = rawUserRecords.filter((user) => {
                if (user.firstName.toLowerCase().includes(trimmedSearchWord)) {
                    return true;
                }
                if (user.familyName?.toLowerCase().includes(trimmedSearchWord)) {
                    return true;
                }
                if (user.email?.toLowerCase().includes(trimmedSearchWord)) {
                    return true;
                }
                if (user.group?.toLowerCase().includes(trimmedSearchWord)) {
                    return true;
                }
                if (user.roleName?.toLowerCase().includes(trimmedSearchWord)) {
                    return true;
                }
                return false;
            });
        } else {
            records = rawUserRecords;
        }
        return records;
    }
    async created(): Promise<void> {
        this.poiGroupId = this.$route.params.poiGroupId as string;
        this.isAdmin = this.user.poiGroupID === 0;
        await this.fetchUsers();
    }

    // MFASetting 権限を持っている場合 true を返す
    isMFASetting(): boolean {
        const roles = this.roleList?.find((x) => x.roleLv === this.user.roleLv);
        if (roles === undefined) {
            // roleが見つからない
            return false;
        }
        // mfasetting:post があれば有効と判定
        return roles.functions?.indexOf("mfasetting:post") >= 0;
    }

    async fetchUsers(): Promise<void> {
        this.loading = true;
        try {
            const stores: { [poiId: string]: string } = {};
            this.stores.stores.forEach((store) => {
                stores[store.poiID] = store.name;
            });
            const users = await uapi.getUsers(parseInt(this.poiGroupId));
            this.users = users.map((user) => {
                const u: EntitiesUser = {
                    ...user,
                    poiGroupID: user.poiGroupID ?? 0, // poiGroupID が 0 だとレスポンスから省かれてしまうため、ここで設定する
                    areas: user.areas ?? [], // areas が空だとレスポンスから省かれてしまうため、ここで追加する
                    stores: user.stores ?? [], // areas が空だとレスポンスから省かれてしまうため、ここで追加する
                };
                const r = this.roleList.find((x) => x.roleLv === u.roleLv);
                return new UserView(this.user, u, this.areas, stores, r);
            });
            this.userRecord = this.users;
        } catch (e) {
            console.error(e);
            throw Error("ユーザー情報の検索に失敗しました。");
        } finally {
            this.loading = false;
        }
    }

    /** ユーザ登録編集画面で選択できるロール、エリア、店舗を取得 */
    getMasters() {
        const poiGroupId = parseInt(this.poiGroupId);
        const roles =
            poiGroupId === 0
                ? this.roleList
                : this.roleList.filter((role) => role.privileged === false);
        const areas = this.areaStores.map((area) => {
            return { isHeader: false, id: area.areaID, title: area.name };
        });
        let { stores } = makeSelectItems(
            this.areaStores,
            this.stores.stores.filter((store) => store.enabled),
            true
        );
        stores = uniq(
            stores.filter((store) => !store.isHeader),
            (a, b) => a.id === b.id
        );
        let { areas: sareas, stores: sstores } = makeSelectItems(
            this.areaStores,
            this.stores.stores
                .filter((store) => store.enabled)
                .filter((store) => store.options?.includes(wordDictionary.stores.options.review)),
            true
        );
        sareas = sareas.filter((area) => !area.isHeader);
        sstores = uniq(
            sstores.filter((store) => !store.isHeader),
            (a, b) => a.id === b.id
        );
        return { roles, areas, stores, sareas, sstores };
    }

    /** XLSXエクスポート */
    async exportXlsx(): Promise<void> {
        const poiGroupId = parseInt(this.poiGroupId);
        // ユーザ登録編集画面で選択できるロール、エリア、店舗を取得
        const { roles, areas, stores, sareas, sstores } = this.getMasters();
        // ユーザ一覧を取得. 設定できるロール以外が設定されたユーザは除外する
        const users = (await uapi.getUsers(poiGroupId)).filter((user) =>
            roles.some((r) => r.roleLv === user.roleLv)
        );
        // ユーザ情報とマスタ情報を元にxlsxファイルに出力するデータを作成
        const aoa = makeXlsxData(users, roles, areas, stores, sareas, sstores);
        // xlsxファイルを作成
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(aoa[0]), "users");
        XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(aoa[1]), "master");
        const buf: ArrayBuffer = XLSX.write(wb, { type: "array", bookType: "xlsx", bookSST: true });
        // ファイル名を作成してその名前でダウンロードさせる
        const companyName = this.company.name;
        const datetime = dayjs().format("YYYYMMDD");
        saveAs(
            new Blob([buf], { type: "application/octet-stream" }),
            `${wordDictionary.service.name}-ユーザ管理-${companyName}-${datetime}.xlsx`
        );
    }

    /** XLSX読み取り */
    async readXlsx(event: Event): Promise<void> {
        // input タグからファイルを取得
        const target = event.target as HTMLInputElement;
        const file: File = target.files[0];
        if (!file) return;
        target.value = "";
        target.files = null;
        // XLSXファイルを読み取る
        const buf = await read(file);
        const book = arrayBufferToStringsArrays(buf);
        const { roles, areas, stores, sareas, sstores } = this.getMasters();
        // XLSXから読み取った情報を元に、ユーザエンティティの配列を作成する
        const [users, errors] = makeImportUserData(book[0], roles, areas, stores, sareas, sstores);
        // 自分自身の権限を変更しようとしていたらエラーにする
        const self = users.find((x) => x.mailAddress === this.user.mailAddress);
        if (self && self.roleLv !== this.user.roleLv) {
            errors.unshift("自分自身の権限を変更することはできません");
        }
        if (errors.length > 0) {
            this.importDialog.message = errors.join("<br>");
            this.importDialog.show = true;
            return;
        }
        const poiGroupId = parseInt(this.poiGroupId);
        const currs = await uapi.getUsers(parseInt(this.poiGroupId));
        for (const curr of currs) {
            curr.areas = curr.areas ?? [];
            curr.stores = curr.stores ?? [];
            curr.reviewSubscriptions = curr.reviewSubscriptions ?? {};
            curr.reviewSubscriptions.areas = curr.reviewSubscriptions.areas ?? [];
            curr.reviewSubscriptions.stores = curr.reviewSubscriptions.stores ?? [];
            curr.locationUpdateNotificationEnabled =
                curr.locationUpdateNotificationEnabled ?? false;
            curr.useMFA = curr.useMFA ?? false;
        }
        const inserts: EntitiesUser[] = [];
        const updates: EntitiesUser[] = [];
        const updateDiffs: { mail: string; diffs: ReturnType<typeof diffUsers> }[] = [];
        for (const user of users) {
            const curr = currs.find((x) => x.mailAddress === user.mailAddress);
            // 既存ユーザが存在しない場合は新規登録
            if (!curr) {
                inserts.push({
                    poiGroupID: poiGroupId,
                    uuID: null,
                    firstName: user.firstName,
                    familyName: user.familyName,
                    mailAddress: user.mailAddress,
                    roleLv: user.roleLv,
                    areas: user.areas,
                    stores: user.stores,
                    isInCharge: false,
                    reviewSubscriptions: {
                        areas: user.reviewSubscriptions.areas,
                        stores: user.reviewSubscriptions.stores,
                    },
                    locationUpdateNotificationEnabled: user.locationUpdateNotificationEnabled,
                    isApprovePostNotificationDisabled: true,
                    useMFA: user.useMFA,
                });
            }
            // 既存ユーザが存在する場合は更新
            else {
                const diffs = diffUsers(curr, user, roles, areas, stores, sareas, sstores);
                if (diffs.length === 0) continue; // 更新がなければスキップ
                updateDiffs.push({ mail: user.mailAddress, diffs });
                updates.push({
                    poiGroupID: poiGroupId,
                    uuID: curr.uuID,
                    firstName: user.firstName,
                    familyName: user.familyName,
                    mailAddress: user.mailAddress,
                    roleLv: user.roleLv,
                    areas: user.areas,
                    stores: user.stores,
                    isInCharge: curr.isInCharge,
                    reviewSubscriptions: {
                        areas: user.reviewSubscriptions.areas,
                        stores: user.reviewSubscriptions.stores,
                    },
                    locationUpdateNotificationEnabled: user.locationUpdateNotificationEnabled,
                    isApprovePostNotificationDisabled: curr.isApprovePostNotificationDisabled,
                    useMFA: user.useMFA,
                });
            }
        }
        if (inserts.length === 0 && updates.length === 0) {
            this.importDialog.message = "変更がありません";
            this.importDialog.show = true;
            return;
        }
        console.log("inserts", inserts);
        console.log("updates", updates);
        this.confirmDialog.inserts = inserts;
        this.confirmDialog.updates = updates;
        this.confirmDialog.updateDiffs = updateDiffs;
        this.confirmDialog.show = true;
    }
    /** インポート処理 */
    async importXlsx(): Promise<void> {
        const poiGroupId = parseInt(this.poiGroupId);
        this.importDialog.show = true;
        this.importDialog.message = "インポート中...";
        this.importDialog.submitButtonDisabled = true;
        this.importDialog.percentage = 0;
        await this.$nextTick();
        // 新規追加と更新をまとめて処理するために、1つの配列にまとめる
        const upserts: { isCreate: boolean; user: EntitiesUser }[] = [];
        for (const user of this.confirmDialog.inserts) {
            upserts.push({ isCreate: true, user });
        }
        for (const user of this.confirmDialog.updates) {
            upserts.push({ isCreate: false, user });
        }
        try {
            for (let i = 0; i < upserts.length; i++) {
                const upsert = upserts[i];
                const procname = upsert.isCreate ? "ユーザ追加処理" : "ユーザ更新処理";
                this.importDialog.message = `${procname}実行中: ${upsert.user.mailAddress}`;
                await this.$nextTick();
                try {
                    if (upsert.isCreate) {
                        await uapi.createUser(poiGroupId, upsert.user);
                    } else {
                        const uuID = upsert.user.uuID;
                        await uapi.updateUser(poiGroupId, uuID, upsert.user);
                        if (!upsert.user.useMFA) {
                            await uapi.updateMfaEnabled(poiGroupId, uuID, upsert.user.useMFA);
                        }
                    }
                    this.importDialog.percentage = Math.floor(((i + 1) / upserts.length) * 100);
                } catch (e) {
                    console.error(e);
                    this.importDialog.message = `${procname}に失敗しました: ${upsert.user.mailAddress}`;
                    return;
                }
            }
            this.fetchUsers();
            this.importDialog.message = "インポートが完了しました";
        } finally {
            this.importDialog.submitButtonDisabled = false;
        }
    }

    generateErrorMessage(e: AxiosError<any>): string {
        const err = e.response.data.errorMessage;
        if (err.includes("UserNotFoundException")) {
            return wordDictionary.users.generateErrorMessage_UserNotFoundException_message;
        } else if (err.includes("ConditionalCheckFailedException")) {
            return wordDictionary.users.generateErrorMessage_delete_unable;
        } else if (err.includes("exceeds")) {
            return wordDictionary.users.generateErrorMessage_exceeds_message;
        } else {
            throw Error(wordDictionary.users.generateErrorMessage_message);
        }
    }

    deleteConfirm(row: UserView): void {
        useDialog().confirm({
            title: "ユーザ削除 : " + row.familyName + " " + row.firstName,
            message: "本当に削除してよろしいですか?",
            type: "error",
            hasIcon: true,
            onConfirm: async () => await this.deleteUser(row.uuid),
        });
    }

    async editUser(row: UserView): Promise<void> {
        this.$router.push({
            name: "UsersEdit",
            params: {
                poiGroupId: this.poiGroupId,
                userId: row.uuid,
            },
        });
    }

    async deleteUser(userId: string): Promise<void> {
        this.loading = true;
        const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
            this.poiGroupId
        }/users/${userId}/${this.isAdmin}`;
        try {
            const response = await requiredAuth<EntitiesUser>("delete", url, null, {});
            if (response.status === 200) {
                this.addSnackbarMessages({
                    text: this.dict.desc_deleted,
                    color: "success",
                });
            }
            await this.fetchUsers();
        } catch (e) {
            console.error(e);
            throw Error(this.generateErrorMessage(e as AxiosError));
        } finally {
            this.loading = false;
        }
    }

    isMyself(row: UserView): boolean {
        return row.uuid === this.user.uuID;
    }
}
export default toNative(Users);
