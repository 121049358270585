<template>
  <div>
    <div class="container-area">
      <SubmitDialog
        :show="dialog.show"
        :title="dialog.title"
        :percentage="dialog.percentage"
        :message="dialog.message"
        :button="!dialog.button"
        @set-show="setSubmitShow"
      />
      <ImportDialog
        :show="importDialog.show"
        :title="importDialog.title"
        :message="importDialog.message"
        :cancel-button="importDialog.cancelButton"
        @on-cancel="onImportCancel"
      />
      <div style="text-sm-left" class="d-flex flex-row justify-start">
        <div class="mr-2">
          <v-select
            v-model="selectLang"
            :disabled="isLoading"
            label="言語の選択"
            density="compact"
            hide-details
            :items="customLangs"
            width="300"
            item-title="lang.caption"
            return-object
            @update:model-value="onChangeLang"
          />
        </div>
        <div class="mr-2">
          <o-upload
            v-if="isHostingEnabled() && canManage"
            v-model="uploadFiles"
            accept=".jpg,.jpeg,.png,.mov,.mp4,.zip,.gz,.tgz"
            multiple
            @update:model-value="hostingImageUpload"
          >
            <a class="button is-primary is-small">
              <span>画像アップロード</span>
            </a>
          </o-upload>
        </div>
        <o-button
          v-if="isHostingEnabled()"
          variant="primary"
          size="small"
          @click="hostingImageListDownload"
        >
          画像リストダウンロード
        </o-button>
      </div>
      <div class="custom-grid">
        <c-grid
          ref="cgrid"
          :data="filterRecords"
          :frozen-col-count="3"
          class="text-sm-left grid-control"
          :font="gridFont"
          :header-row-height="61"
          :theme="customTheme()"
          :allow-range-paste="false"
          :delete-cell-value-on-del-key="true"
          :move-cell-on-tab-key="true"
          :move-cell-on-enter-key="true"
          :select-all-on-ctrl-a-key="true"
          @changed-value="onChangeValue"
        >
          <template v-for="c in getCustomColumns()" :key="c.id">
            <c-grid-check-column
              v-if="c.columnType === 'BOOL'"
              :key="c.id"
              :field="c.field"
              :width="c.width"
              :min-width="c.minWidth"
              :sort="sortColumn"
              :readonly="!canManage"
            >
              {{ c.caption }}
            </c-grid-check-column>
            <c-grid-input-column
              v-if="c.columnType === 'CAPTION'"
              :key="c.id"
              :field="c.field"
              :width="c.width"
              :min-width="c.minWidth"
              :sort="sortColumn"
              :readonly="true"
            >
              {{ c.caption }}
            </c-grid-input-column>
            <c-grid-input-column
              v-if="c.columnType === 'STRING'"
              :key="c.id"
              :field="c.field"
              :width="c.width"
              :min-width="c.minWidth"
              :sort="sortColumn"
              :readonly="!canManage"
            >
              {{ c.caption }}
            </c-grid-input-column>
            <c-grid-input-column
              v-if="c.columnType === 'INT64'"
              :key="c.id"
              :field="c.field"
              :width="c.width"
              :min-width="c.minWidth"
              input-type="number"
              :sort="sortColumn"
              :readonly="!canManage"
            >
              {{ c.caption }}
            </c-grid-input-column>
            <c-grid-input-column
              v-if="c.columnType === 'FLOAT64'"
              :key="c.id"
              :field="c.field"
              :width="c.width"
              :min-width="c.minWidth"
              input-type="number"
              :sort="sortColumn"
              :readonly="!canManage"
            >
              {{ c.caption }}
            </c-grid-input-column>
          </template>
        </c-grid>
        <div v-if="isLoading" class="progress-circular-container">
          <v-progress-circular :size="80" :width="4" color="primary" indeterminate />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, toNative } from "vue-facing-decorator";
import axios from "axios";
import type { AxiosRequestConfig } from "axios";

import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { isBoolean, isNumber, isString, isNaN, parseInt, isUndefined } from "lodash";

import wordDictionary from "@/word-dictionary";
import type {
  EntitiesStoresInfoValueList,
  EntitiesStoresInfoValues,
  EntitiesStoresInfoColumns,
  EntitiesStore,
  ControllersGetMasterOutput,
  ControllersGetValuesOutput,
  ControllersPostValuesInput,
  ControllersExecStoresinfoOutput,
  ControllersPostValuesOutput,
  ControllersGetImagesOutput,
  ControllersGetUploadImagePathOutput,
  ControllersDeploymentImageFilesOutput,
  ControllersCDNCacheCleanOutput,
} from "@/types/ls-api";
import { requiredAuth, sleep } from "@/helpers";
import { read, arrayBufferToCsv } from "@/helpers/xlsxtools";
import ImportDialog from "./import-dialog.vue";
import SubmitDialog from "./submit-dialog.vue";
import { getter } from "@/storepinia/idxdb";
import { currentTheme } from "@/components/shared/theme";
import type { ListGrid, ColumnDefine } from "cheetah-grid";
import type { CustomSortState } from "@/components/shared/cheetah-grid-shared";

type DefaultBgColorArgs = {
  row: number;
  col: number;
  grid: ListGrid<CustomRecord>;
};

class CustomLang {
  constructor(langID: number, lang: EntitiesStoresInfoColumns) {
    this.id = langID;
    this.lang = lang;
  }

  id: number;
  lang: EntitiesStoresInfoColumns;
  toString = (): string => {
    return this.lang.caption;
  };
}
class CustomColumn {
  constructor(
    id: number,
    number: number,
    field: string,
    caption: string,
    width: string,
    minWidth: number,
    columnType: string
  ) {
    this.id = id;
    this.number = number;
    this.field = field;
    this.caption = caption;
    this.width = width;
    this.minWidth = minWidth;
    this.columnType = columnType;
  }
  id: number;
  number: number;
  field: string;
  caption: string;
  width: string;
  minWidth: number;
  columnType: string;
}

class CustomRecord {
  constructor(
    poiID: number,
    storeCode: string,
    name: string,
    columns: CustomColumn[],
    areas: number[]
  ) {
    this.poiID = poiID;
    this.storeCode = storeCode;
    this.name = name;
    this.columns = columns;
    this.areas = areas;
  }

  // 店舗ID
  poiID: number;
  // 店舗コード
  storeCode: string;
  // 店舗名
  name: string;
  // columnType="CAPTION"を除いたカラムリスト
  columns: CustomColumn[];
  // areas 所属しているグループID一覧
  areas: number[];

  // カラムのfieldが存在していない場合に初期値をセットしておく
  createEmpty() {
    for (const c of this.columns) {
      if (!(c.field in this)) {
        switch (c.columnType) {
          case "BOOL":
            this[c.field] = false;
            break;
          case "STRING":
            this[c.field] = "";
            break;
          case "INT64":
            this[c.field] = "";
            break;
          case "FLOAT64":
            this[c.field] = "";
            break;
        }
      }
    }
    return this;
  }

  check(canManage: boolean): boolean {
    // 編集権限がない場合は、変更無しと判定);
    if (!canManage) {
      return false;
    }
    const columns = this.columns; // コピーしておかないと、なぜか以下のチェックで参照できない？
    if (!("origin" in this)) {
      return true; // 元データがない場合、新しく追加された行と判定して更新有り
    }
    // 元データがある場合
    const org = this["origin"] as any;
    for (const c of columns) {
      const o = c.field in org ? org[c.field] : null;
      const v = c.field in this ? this[c.field] : null;
      switch (c.columnType) {
        case "BOOL":
          {
            const bo = isBoolean(o) ? o : false;
            const bv = isBoolean(v) ? v : false;
            if (bo !== bv) {
              return true;
            }
          }
          break;
        case "STRING":
          {
            const so = isString(o) ? o : "";
            const sv = isString(v) ? v : "";
            if (so !== sv) {
              return true;
            }
          }
          break;
        case "INT64":
          {
            const io = o === "NaN" || isNaN(o) ? 0 : isNumber(o) ? o : parseInt(o);
            const vo = v === "NaN" || isNaN(v) ? 0 : isNumber(v) ? v : parseInt(v);
            if (io !== vo) {
              return true;
            }
          }
          break;
        case "FLOAT64":
          {
            const fo = o === "NaN" || isNaN(o) ? 0.0 : isNumber(o) ? o : parseFloat(o);
            const fv = v === "NaN" || isNaN(v) ? 0.0 : isNumber(v) ? v : parseFloat(v);
            if (fo !== fv) {
              return true;
            }
          }
          break;
      }
    }
    return false; // 差分無しと判定
  }

  // xlsxインポートで新しい値をセットする際に呼び出される
  // rec: xlsxを変換してカラム名と文字列の連想配列
  updateXLSX(rec: any) {
    Object.keys(rec).forEach((x) => {
      const f = this.columns.find((c) => c.field === x);
      if (typeof f !== "undefined") {
        this[f.field] = this.convertXLSX(f.columnType, rec[x]);
      }
    });
  }
  // xlsxへ出力する際に値をExcelで認識しやすくするための変換
  private convertXLSX(columnType: string, value: string): any {
    switch (columnType) {
      case "STRING":
        // 改行コードはすべてLFへ変換して読み込む
        return value.replace(/\r\n/g, "\n").replace(/\r/g, "\n");
      case "INT64":
        if (value === "NaN") {
          return "";
        }
        return parseInt(value);
      case "FLOAT64":
        if (value === "NaN") {
          return "";
        }
        return parseFloat(value);
      case "BOOL":
        return value === "o";
    }
    return value;
  }
  // 指定されたフィールド名へ指定された型で値を設定する
  setData(values: { [key: string]: string }) {
    for (const key in values) {
      const id = parseInt(key);
      const column = this.columns.find((c) => c.id === id);
      if (column !== undefined && column.columnType !== "CAPTION") {
        if (!("origin" in this)) {
          this["origin"] = {};
        }
        this[column.field] = this["origin"][column.field] = this.convertData(
          column.columnType,
          values[key]
        );
      }
    }
  }
  // カラム種別と値から実際の値に変換
  private convertData(columnType: string, value: any): any {
    switch (columnType) {
      case "STRING":
        return value;
      case "INT64":
        return parseInt(value);
      case "FLOAT64":
        return parseFloat(value);
      case "BOOL":
        return value === "True";
    }
    return value;
  }
  // 指定されたフィールド名の変更前・変更済みデータへ指定された型で値を設定する（変更内容を反映時に使用する）
  resetOriginData(values: { [key: string]: string }) {
    //   field: string, columnType: string, value: any
    if (!("origin" in this)) {
      this["origin"] = {};
    }
    for (const key in values) {
      const id = parseInt(key);
      const column = this.columns.find((c) => c.id === id);
      if (column !== undefined && column.columnType !== "CAPTION") {
        this[column.field] = this["origin"][column.field] = this.convertData(
          column.columnType,
          values[key]
        );
      }
    }
  }
}

interface UpdateError {
  poiID: number;
  messages: string[];
}

@Component({
  components: {
    ImportDialog,
    SubmitDialog,
  },
  emits: ["changeUpdate", "recordsUpdate"],
})
class StorepageHosting extends Vue {
  company = getter().company;
  allStores = getter().stores;
  stores: EntitiesStore[] = [];
  canManage = getter().canManageCustomPage;
  isLoading = false;
  @Prop({ default: "", type: String, required: false })
  searchWord: string;
  @Prop({
    default: [],
    type: Array,
    required: false,
  })
  areaList: number[];

  gridFontSize = 12.8;
  gridFont = `${this.gridFontSize}px sans-serif`;

  private onlyDirtyRows = false;

  uploadFiles: File[] = [];

  async created(): Promise<void> {
    this.poiGroupId = this.company.poiGroupID;
    this.stores = this.allStores.stores.filter(
      (s) => s.enabled && s.options?.includes("structuredPage")
    );
    this.stores.forEach((s) => {
      this.poiNameMap[s.poiID] = s.name;
      this.poiAreaMap[s.poiID] = s.areas;
    });
    this.isLoading = true;
    await this.customMasterFetch();
  }

  // 変更行のみ表示にチェック入れたら/外したら
  usePickingDirtyRows(onlyDirtyRows: boolean): void {
    this.onlyDirtyRows = onlyDirtyRows;
    this.updateFilterRecords();
  }

  // this.recordsへ追加・削除を行ったか、フィルターキーワード/グループ選択の変更があったら呼び出す
  async updateFilterRecords(): Promise<void> {
    if (
      this.onlyDirtyRows === false &&
      (this.searchWord == "" || this.searchWord === null) &&
      this.areaList.length == 0
    ) {
      // 変更行のみチェックも検索キーワードの指定もなかったら
      this.filterRecords = this.records;
    } else if (this.onlyDirtyRows === false) {
      // 検索キーワードによる絞り込みの場合
      this.filterRecords = [];
      this.records.forEach((s) => {
        if (this.searchWordHitCheck(s) && this.areaListHitCheck(s)) {
          this.filterRecords.push(s);
        }
      });
    } else {
      // 検索キーワードに加えて変更行のみチェックも入ってたら
      this.filterRecords = [];
      this.records.forEach((s) => {
        if (this.searchWordHitCheck(s) && this.areaListHitCheck(s) && s.check(this.canManage)) {
          this.filterRecords.push(s);
        }
      });
    }
    (this.$refs.cgrid as ListGrid<CustomRecord>).invalidate();

    this.$emit("recordsUpdate", this.filterRecords.length);
    this.onChangeValue(null);
  }

  searchWordHitCheck(s: CustomRecord): boolean {
    if (this.searchWord == null || this.searchWord == "") {
      return true;
    }
    const keys = Object.keys(s);
    for (const i in keys) {
      const key = keys[i];
      const value = s[key];
      if (isBoolean(value)) {
        if (value.toString() === this.searchWord.toLowerCase()) {
          return true;
        }
        continue;
      }
      if (isString(value)) {
        if (value.indexOf(this.searchWord) >= 0) {
          return true;
        }
        continue;
      }
      if (isNumber(value)) {
        if (value.toString().indexOf(this.searchWord) >= 0) {
          return true;
        }
        continue;
      }
    }
    return false;
  }

  areaListHitCheck(s: CustomRecord): boolean {
    if (this.areaList == null || this.areaList.length === 0) {
      return true;
    }
    for (const i in s.areas) {
      if (this.areaList.includes(s.areas[i])) {
        return true;
      }
    }
    return false;
  }

  getCustomColumns(): CustomColumn[] {
    return Object.keys(this.customColumns)
      .map((ci) => this.customColumns[ci])
      .sort((a: CustomColumn, b: CustomColumn) => {
        if (a.number == b.number) {
          return a.id - b.id;
        }
        return a.number - b.number;
      });
  }

  // 企業ID
  poiGroupId = 0;
  // poiIDから店舗名を検索する連想配列
  poiNameMap: { [id: string]: string } = {};
  // poiIDから所属グループID一覧を検索する連想配列
  poiAreaMap: { [id: string]: number[] } = {};
  // 選択している言語
  selectLang: CustomLang | undefined;
  // 表示しているグリッドのレコード一覧
  records: CustomRecord[] = [];
  // フィルターを通したあとのレコード一覧
  filterRecords: CustomRecord[] = [];
  // APIから受け取ったマスタ
  customMaster: ControllersGetMasterOutput | undefined;
  // APIから受け取った値リスト
  customValues: EntitiesStoresInfoValues[] = [];

  // マスタ情報を元にした言語一覧
  customLangs: CustomLang[] = [];
  // マスタ情報を元に作成したカラム一覧
  customColumns: { [id: number]: CustomColumn } = {};

  customTheme() {
    const checkBoxTheme = {
      borderColor: currentTheme().vuetifyTheme.colors.primary,
      uncheckBgColor: "#fff",
      checkBgColor: currentTheme().vuetifyTheme.colors.primary,
    };
    if (this.canManage) {
      return {
        checkbox: checkBoxTheme,
        // 変更検出を行い、変更箇所を色付けする
        defaultBgColor({ col, row, grid }: DefaultBgColorArgs): "#f0f0f0" | "#aaffff" {
          if (col < grid.frozenColCount || row < grid.frozenRowCount) {
            // 固定領域＋ヘッダ領域
            return "#f0f0f0";
          }
          // カラムの種別がわからないので、値から差分判定している
          const hdr = grid.header[col] as ColumnDefine<CustomRecord>;
          const rec = grid.dataSource.source[row - 1];
          // 元データが設定されている場合
          if ("origin" in rec) {
            const o = rec["origin"][hdr.field.toString()]; // 変更前
            const v = rec[hdr.field.toString()]; // 最新値
            // どちらもNaNであれば、差分無しと判定
            if (isNaN(o) && isNaN(v)) {
              return null;
            }
            // 片方が空文字で、もう片方がNaNであれば、差分無し（数値の入力を空に更新すると発生する）
            if ((isNaN(o) && v === "") || (o === "" && isNaN(v))) {
              return null;
            }
            // 片方が空文字で、もう片方がundefinedであれば、差分なし（文字列の入力が空の場合に発生する)
            if ((isUndefined(o) && v === "") || (o === "" && isUndefined(v))) {
              return null;
            }
            // 片方がfalseで、もう片方がundefinedであれば、差分なし（チェックボックスの入力が空の場合に発生する)
            if ((isUndefined(o) && v === false) || (o === false && isUndefined(v))) {
              return null;
            }
            if (o == v) {
              return null;
            }
          }
          return "#aaffff";
        },
      };
    }
    // 閲覧専用の場合
    return {
      checkbox: checkBoxTheme,
      defaultBgColor({ col, row, grid }: DefaultBgColorArgs): "#f0f0f0" {
        if (col < grid.frozenColCount || row < grid.frozenRowCount) {
          // 固定領域＋ヘッダ領域
          return "#f0f0f0";
        }
        return null;
      },
    };
  }

  // 変更検出中の場合はtrue
  changeUpdate = false;
  // 変更内容を反映する際に使用するダイアログパラメータ
  dialog = {
    show: false,
    percentage: 0,
    title: "",
    message: "",
    button: true,
  };
  // インポート時に使用するダイアログパラメータ
  importDialog = {
    show: false,
    title: "",
    message: "",
    cancelButton: "",
  };
  // 変更内容反映時に処理完了数をカウントアップするための変数
  updateCount = 0;
  // 変更内容反映時に処理総数をセットしておくための変数
  updateTotal = 0;

  customMasterFetchRetry = 0;

  // ホスティングに必要なオプションが有効であればtrueを返す
  isHostingEnabled(): boolean {
    return (
      this.company.options.includes("hosting") &&
      this.company.custom?.hostingS3RootPath?.length > 0 &&
      this.company.custom?.hostingRootURL?.length > 0 &&
      this.company.custom?.hostingImagePath?.length > 0
    );
  }

  // カスタムマスタ情報を取得
  async customMasterFetch(): Promise<void> {
    this.$emit("recordsUpdate", 0);
    await requiredAuth<ControllersGetMasterOutput>(
      "get",
      `${import.meta.env.VITE_APP_API_BASE}v2/companies/${this.poiGroupId}/storesinfo/master`
    )
      .then((response) => {
        this.customMaster = response.data;
        this.customValuesFetch();
        // 言語リストを生成
        this.customLangs = [];
        for (const key in response.data.LangMasterColumns) {
          const id = parseInt(key);
          this.customLangs.push(new CustomLang(id, response.data.LangMasterColumns[key]));
        }
        // 言語リストをソート
        this.customLangs.sort((a, b) =>
          a.lang.number < b.lang.number || (a.lang.number == b.lang.number && a.id < b.id) ? -1 : 1
        );
        this.customMasterFetchRetry = 0;
      })
      .catch((err) => {
        this.isLoading = false;
        if (err?.response?.status === 404) {
          alert("カスタムマスタ情報が見つかりませんでした");
          return;
        }
        // エラーが発生した場合、再度取得を実施
        this.customMasterFetchRetry++;
        if (this.customMasterFetchRetry > 5) {
          alert(`マスタ情報の取得に失敗しました ${err}`);
          this.customMasterFetchRetry = 0;
        } else {
          console.log(err);
          this.customMasterFetch();
        }
      });
  }

  customValuesFetchRetry = 0;

  // カスタム値を取得
  async customValuesFetch(lastEvaluatedPoiID: number = 0): Promise<void> {
    if (lastEvaluatedPoiID === 0) {
      this.customValues = [];
    }
    await requiredAuth<ControllersGetValuesOutput>(
      "get",
      `${import.meta.env.VITE_APP_API_BASE}v2/companies/${this.poiGroupId}/storesinfo/values`,
      { lastEvaluatedPoiID: lastEvaluatedPoiID, limit: 100 }
    )
      .then((response) => {
        if (response?.data?.values) {
          this.customValues = this.customValues.concat(response.data.values);
        }
        if (response?.data?.lastEvaluatedPoiID > 0) {
          // 続きがある
          this.customValuesFetch(response.data.lastEvaluatedPoiID);
        } else {
          // 取得完了
          this.isLoading = false;
          // 言語が1つの場合は自動で選択
          if (this.customLangs.length === 1) {
            this.selectLang = this.customLangs[0];
            this.onChangeLang(this.customLangs[0]);
          }
          this.customValuesFetchRetry = 0;
        }
      })
      .catch((err) => {
        // エラーが発生した場合、再度取得を実施
        this.customValuesFetchRetry++;
        if (this.customValuesFetchRetry > 5) {
          this.isLoading = false;
          alert(`値の取得に失敗しました ${err}`);
          this.customValuesFetchRetry = 0;
        } else {
          console.log("customColumnsFetch catch", err);
          this.customValuesFetch();
        }
      });
  }

  // 言語選択
  onChangeLang(item: CustomLang): void {
    this.selectLang = item;
    this.updateFields();
  }

  // グリッド表示を初期化
  updateFields(): void {
    // 選択されている言語のカラム情報を作成
    this.customColumns = {};
    const c1 = new CustomColumn(-3, -3, "poiID", "店舗ID", "80px", 80, "CAPTION");
    this.customColumns[c1.id] = c1;
    const c2 = new CustomColumn(-2, -2, "storeCode", "店舗コード", "90px", 90, "CAPTION");
    this.customColumns[c2.id] = c2;
    const c3 = new CustomColumn(-1, -1, "name", "ビジネス名", "220px", 220, "CAPTION");
    this.customColumns[c3.id] = c3;
    const langColumns = this.customMaster?.LangMasterColumns[this.selectLang?.id];
    const keyList = Object.keys(langColumns?.columns).map((k) => parseInt(k));
    keyList.sort((a, b) => {
      const aColumn = langColumns?.columns[a];
      const bColumn = langColumns?.columns[b];
      if (aColumn.number == bColumn.number) {
        return a - b;
      }
      return aColumn.number - bColumn.number;
    });
    for (const id of keyList) {
      const column = langColumns.columns[id.toString()];
      if (column.columnType === "REFERENCE") {
        continue; // 参照型は除外
      }
      this.customColumns[id] = new CustomColumn(
        id,
        column.number,
        column.columnName,
        column.caption,
        "auto",
        this.countCharacter(column.caption),
        column.columnType
      );
    }

    // 選択されている言語の値を作成
    this.records = [];
    const columns = this.getCustomColumns().filter((c) => c.columnType !== "CAPTION");
    this.stores.forEach((store) => {
      const values = this.customValues?.find((v) => v.poiID === store.poiID);
      if (values) {
        const record = new CustomRecord(
          store.poiID,
          store.gmbStoreCode,
          this.poiNameMap[store.poiID],
          columns,
          store.areas
        );
        const langValues = values.langValues[this.selectLang.id];
        if (langValues !== undefined) {
          record.setData(langValues.values);
        } else {
          record.createEmpty();
        }
        this.records.push(record);
      } else {
        // 店舗が未設定なので、新規追加
        this.records.push(
          new CustomRecord(
            store.poiID,
            store.gmbStoreCode,
            this.poiNameMap[store.poiID],
            columns,
            store.areas
          ).createEmpty()
        );
      }
    });
    this.updateFilterRecords();
  }

  // グリッド表示に差分があるかを判定して、「変更内容を反映」ボタンの状態を書き換える
  onChangeValue(arg: Record<string, any> | null): void {
    if (arg !== null && "record" in arg && arg.record?.check(this.canManage)) {
      // 変更対象の行に差分があると判定
      this.$emit("changeUpdate", true);
      return;
    }
    // 全体を検索して、差分をチェック
    for (let i = 0; i < this.records.length; i++) {
      if (this.records[i].check(this.canManage)) {
        // 差分を検出
        this.$emit("changeUpdate", true);
        return;
      }
    }
    // 差分はなかったと判定
    this.$emit("changeUpdate", false);
  }

  // グリッド内に保持しているデータを、API側で扱えるように文字列へ変換する
  convertRESTValue(value: string | number | boolean | null, columnType: string): string {
    if (columnType === "BOOL") {
      if (value === null || value === undefined || value !== true) {
        return "False";
      }
      return "True";
    }
    if (columnType === "INT64" || columnType === "FLOAT64") {
      if (
        value === null ||
        value === undefined ||
        isNaN(value) ||
        (isString(value) && value === "NaN")
      ) {
        return "";
      }
      return value.toString();
    }
    return value === null || value === undefined ? "" : value.toString();
  }

  // 変更内容を反映
  async submit(): Promise<void> {
    this.dialog.show = true;
    this.updateCount = 0;
    this.updateTotal = 0;
    this.dialog.percentage = 0;
    this.dialog.message = "";

    // フィールド名からカラム情報を調べる連想配列(poiID / nameは除外)
    const fields: { [field: string]: CustomColumn } = {};
    this.getCustomColumns()
      .filter((f) => f.columnType !== "CAPTION")
      .forEach((f) => (fields[f.field] = f));
    // 更新用
    const reqList: CustomRecord[] = [];

    // グリッド用に保持している全レコードから更新している行を探す
    for (const i in this.records) {
      const r: CustomRecord = this.records[i];
      if ("origin" in r) {
        if (r.check(this.canManage)) {
          // 編集した行と判定
          reqList.push(r);
        }
      } else {
        // 新規追加した行と判定して追加
        reqList.push(r);
      }
    }

    // 反映用
    const workers = [];
    if (reqList.length > 0) {
      const p = new Promise((resolve, reject) => {
        const errors = this.requestPosts(reqList);
        resolve(errors);
      });
      workers.push(p);
    }

    this.dialog.title = "変更内容を反映中";
    this.dialog.button = false;
    this.updateTotal = reqList.length; // 更新対象店舗数をセット
    await new Promise((resolve) => setTimeout(resolve, 100)); // ダイアログ表示のため少しSleep

    Promise.all(workers).then((res) => {
      let errorMessage = "";
      for (const errors of res) {
        for (const error of errors) {
          errorMessage += "--------------------<br/>";
          errorMessage += error.poiID + "<br/>";
          for (const message of error.messages) {
            errorMessage += message + "<br/>";
          }
        }
      }
      // すべてのデータ更新が完了したので、店舗ページの再作成を実行
      requiredAuth<ControllersExecStoresinfoOutput>(
        "post",
        `${import.meta.env.VITE_APP_API_BASE}v2/companies/${
          this.poiGroupId
        }/storesinfo/execStoresinfo`
      )
        .then((res) => {
          if (res.status !== 200) {
            errorMessage += "--------------------<br/>";
            errorMessage += `店舗ページの出力開始が失敗しました ${res.status} <br/>`;
          }
        })
        .catch((e) => {
          errorMessage += "--------------------<br/>";
          errorMessage += `店舗ページの出力開始が失敗しました ${e} <br/>`;
        })
        .finally(() => {
          let dialogTitle = "反映が完了しました";
          let dialogMessage = `${this.updateTotal} 店中 ${this.updateCount} 店反映しました。<br/><br/>`;
          if (errorMessage.length > 0) {
            console.log("errorMessage: %s", errorMessage);
            dialogTitle += " (エラーあり)";
            let message = "以下の店舗はエラーがあり反映できませんでした。<br/>";
            message += "エラー内容をご確認の上、修正と再反映をお試しください。<br/>";
            message += errorMessage;
            dialogMessage += message;
          }
          this.dialog.percentage = 100;
          this.dialog.button = true;
          this.dialog.title = dialogTitle;
          this.dialog.message = dialogMessage;
          this.usePickingDirtyRows(false);
        });
    });
  }

  async requestPosts(reqPostList: CustomRecord[]): Promise<UpdateError[]> {
    const errors: UpdateError[] = [];
    // 10店舗づつまとめてpost
    for (let i = 0; i < reqPostList.length; i += 10) {
      const req: ControllersPostValuesInput = { values: [] };
      for (let j = i; j < reqPostList.length && j < i + 10; j++) {
        const r = reqPostList[j];
        const oldValue = this.customValues.find((v) => v.poiID === r.poiID);
        if (oldValue === undefined) {
          // 新規登録店舗
          const rec: EntitiesStoresInfoValues = {
            poiGroupID: this.poiGroupId,
            poiID: r.poiID,
            langValues: {},
          };
          const values: EntitiesStoresInfoValueList = { values: {} };
          r.columns.forEach((c) => {
            if (c.columnType !== "CAPTION") {
              values.values[c.id] = this.convertRESTValue(r[c.field], c.columnType);
            }
          });
          rec.langValues[this.selectLang.id] = values;
          req.values.push(rec);
          // 新しい店舗情報を記録することで、次回の更新時にこの値を元に更新する
          this.customValues.push(rec);
        } else {
          // 更新店舗
          const values = oldValue.langValues[this.selectLang.id];
          r.columns.forEach((c) => {
            if (c.columnType !== "CAPTION") {
              values.values[c.id] = this.convertRESTValue(r[c.field], c.columnType);
            }
          });
          const rec = oldValue;
          rec.langValues[this.selectLang.id] = values;
          req.values.push(rec);
        }
      }

      await requiredAuth<ControllersPostValuesOutput>(
        "post",
        `${import.meta.env.VITE_APP_API_BASE}v2/companies/${this.poiGroupId}/storesinfo/values`,
        null,
        req
      )
        .then((res) => {
          if (res.status !== 200) {
            // lambdaの応答が200以外の場合はエラーとして扱う
            req.values.forEach((rec) => {
              errors.push({
                poiID: rec.poiID,
                messages: [JSON.parse(res?.request?.response)?.errorMessage],
              });
            });
          } else {
            // 成功したらグリッドデータのoriginに新しい値をセットする
            req.values.forEach((record) => {
              const values = record.langValues[this.selectLang.id].values;
              const t = this.records.find((r) => r.poiID === record.poiID);
              if (t !== undefined) {
                t.resetOriginData(values);
              }
            });
          }
          // プログレスバーを進捗させる
          this.updateCount += req.values.length;
          this.dialog.percentage = (this.updateCount / this.updateTotal) * 100;
        })
        .catch((e: any) => {
          req.values.forEach((rec) => {
            errors.push({
              poiID: rec.poiID,
              messages: [this.getErrorMessage(e)],
            });
          });

          // プログレスバーを進捗させる
          this.updateCount += req.values.length;
          this.dialog.percentage = (this.updateCount / this.updateTotal) * 100;
        });
    }
    return errors;
  }

  getErrorMessage(e: {
    response: { data: { message: string; errorMessage: any } };
    message: any;
  }): string {
    let errorMessage = "";
    if (e?.response?.data?.message) {
      // API Gatewayのエラー
      errorMessage = e.response?.data?.message ?? "想定外のエラー";
      errorMessage = errorMessage.replace(
        "Endpoint request timed out",
        "30秒経ってもサーバーから応答がありませんでした。<br/>お手数ですが画面を読み込み直して、更新に成功しているかご確認ください。"
      );
    } else {
      errorMessage =
        `時間を置いても同じエラーになる場合、お手数ですが担当者までお問い合わせください。<br/><br/>・${e?.message}<br/>` +
        `・${e?.response?.data?.errorMessage}`;
    }
    return errorMessage;
  }

  // xlsxファイルから読み込み
  async importFile(xlsxFile: File): Promise<void> {
    let fields: string[];
    let rows: any[];
    let sheetName: string;
    try {
      const buf = await read(xlsxFile);
      [fields, rows, sheetName] = arrayBufferToCsv(buf);
      if (fields.length === 0) {
        this.showImportDialog("XLSXインポート", "XLSXに ヘッダ行 がありません", "キャンセル");
        return;
      }
      if (sheetName !== this.selectLang.lang.caption) {
        this.showImportDialog(
          "XLSXインポート",
          `XLSXファイルのシート名が言語と一致しません ${sheetName}`,
          "キャンセル"
        );
        return;
      }
    } catch (e) {
      console.log(e);
      this.showImportDialog("XLSXインポート", "XLSXの読み込みに失敗しました", "キャンセル");
      return;
    }

    const columns = this.getCustomColumns();
    if (columns.length != fields.length) {
      this.showImportDialog(
        "XLSXインポート",
        `XLSXのカラム数が一致しません ${columns.length} != ${fields.length}(XLSXファイルのカラム数)`,
        "キャンセル"
      );
      return;
    }
    // 順番通りにカラムが並んでいなければエラー扱いとする
    for (let i = 1; i < columns.length; i++) {
      if (fields[i].trim() != columns[i].field) {
        this.showImportDialog(
          "XLSXインポート",
          `XLSXのカラム名が一致しません ${columns[i].field} != ${fields[i]}(XLSXファイルのカラム名)`,
          "キャンセル"
        );
        return;
      }
    }

    // 知らないカラムがあったらインポート完了時に警告を出す
    const columnTitles = columns.map((c) => c.field);
    const unknownFields = [];
    fields.forEach((field) => {
      if (columnTitles.includes(field) === false) unknownFields.push(field);
    });

    let count = 0;
    const ignoreList: number[] = [];
    // テーブルにXLSXの内容を反映する
    for (const row of rows) {
      const poiID = row[columns[0].field];
      const srcrownum = this.records.findIndex((srcrow) => `${srcrow.poiID}` === `${poiID}`);
      if (srcrownum < 0) {
        // テーブルに存在しないpoiIDは無視する
        ignoreList.push(poiID);
        continue;
      }
      // 更新対象のデータを特定
      const srcrow = this.records[srcrownum];
      // 更新処理
      srcrow.updateXLSX(row);
      count++;
    }

    let message = `${count} 行 インポートしました。`;
    if (unknownFields.length > 0) {
      message += `<br/>対応していないカラムを読み飛ばしました[${unknownFields.join(",")}]`;
    }
    if (ignoreList.length > 0) {
      message += `<br/>存在しないpoiIDを読み飛ばしました[${ignoreList.join(",")}]`;
    }
    this.showImportDialog("XLSXインポート", message, "OK");

    this.updateFilterRecords();
  }

  // xlsxファイルへ出力
  exportFile(): void {
    if (this.selectLang === undefined) {
      // 言語選択していなければ終了（ボタンが表示されないはず）
      return;
    }
    const aoa = [];
    // カラム一覧を追加
    const columns = this.getCustomColumns();
    aoa.push(columns.map((ci) => ci.field));
    // データ一覧を追加
    this.records.forEach((rec) => {
      const record = [];
      columns.map((c) => {
        if (c.field in rec) {
          switch (c.columnType) {
            case "CAPTION":
              record.push(rec[c.field]);
              break;
            case "INT64":
              if (rec[c.field] === "NaN" || isNaN(rec[c.field])) {
                record.push("");
              } else {
                record.push(rec[c.field]);
              }
              break;
            case "FLOAT64":
              if (rec[c.field] === "NaN" || isNaN(rec[c.field])) {
                record.push("");
              } else {
                record.push(rec[c.field]);
              }
              break;
            case "BOOL":
              // booleanは o / x へ変換して出力
              record.push(rec[c.field] ? "o" : "x");
              break;
            case "STRING":
              if (rec[c.field] === undefined || rec[c.field] === null) {
                record.push("");
              } else {
                record.push(rec[c.field]);
              }
              break;
          }
        } else if (c.columnType == "BOOL") {
          // フィールドがセットされていないが、カラムがboolの場合は false 扱いで出力
          record.push("x");
        } else {
          // フィルドがセットされていなく、カラムがbool以外の場合は空白で出力
          record.push("");
        }
      });
      aoa.push(record);
    });
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(aoa);
    // シート名は言語名を設定
    XLSX.utils.book_append_sheet(wb, ws, this.selectLang.lang.caption);
    const buf: ArrayBuffer = XLSX.write(wb, {
      type: "array",
      bookType: "xlsx",
      bookSST: true,
    });
    const companyName = this.company.name;
    const datetime = dayjs().format("YYYYMMDD");
    const typeName = "カスタム情報編集";
    const filename = `${wordDictionary.service.name}-${typeName}エクスポート-${companyName}-${datetime}.xlsx`;
    saveAs(new Blob([buf], { type: "application/octet-stream" }), filename);
  }

  setSubmitShow(show: boolean): void {
    this.dialog.show = show;
    // 変更内容を反映ダイアログを消したタイミングでグリッドの内容をチェックしないと、変更が反映されない
    (this.$refs.cgrid as ListGrid<CustomRecord>).invalidate();
    this.onChangeValue(null);
  }

  showImportDialog(title: string, message: string, cancelButton: string): void {
    this.importDialog.title = title;
    this.importDialog.message = message;
    this.importDialog.cancelButton = cancelButton;
    this.importDialog.show = true;
  }
  onImportCancel(): void {
    this.importDialog.show = false;
    this.$emit("onImportCancel");
  }
  private countCharacter(str: string): number {
    let len = 0;
    str = escape(str);
    for (let i = 0; i < str.length; i++, len++) {
      if (str.charAt(i) === "%") {
        if (str.charAt(++i) === "u") {
          i += 3;
          len++;
        }
        i++;
      }
    }
    // 単純に文字数からカラム幅算出しようとしても何故か良い塩梅にならないので目分量による補正も加えとく
    len = len * Math.floor(this.gridFontSize - len * 0.2);
    len = len < 100 ? 100 : len;
    return len;
  }

  // 直前に行ったソート順と対象列の保存
  private customSortState: CustomSortState = {
    col: 0,
    order: null,
  };
  sortColumn(order: string, col: number, grid: ListGrid<CustomRecord>): void {
    if (col !== this.customSortState.col) {
      // 直前のと違う列をソートしようとした場合はorderを初期化する
      this.customSortState.order = "desc";
      this.customSortState.col = col;
    } else {
      switch (this.customSortState.order) {
        case null:
          this.customSortState.order = "desc";
          break;
        case "desc":
          this.customSortState.order = "asc";
          break;
        case "asc":
          this.customSortState.order = null;
      }
    }
    // 実際にデータをソートする
    this.sortRecord(col, this.customSortState.order);
    // ↑↓マーク制御
    grid.sortState.order = this.customSortState.order;
  }

  private sortRecord(col: number, order: string | null = "asc"): void {
    // 昇順降順の指定がない場合はpoiIDでの昇順ソートにする
    if (order === null) {
      col = 0;
      order = "asc";
    }
    const orderVal = order === "asc" ? 1 : -1;
    const columns = this.getCustomColumns();
    const column = columns[col];
    if (column === undefined) {
      return;
    }
    const key = column.field;
    let records: CustomRecord[];
    let emptyRecords: CustomRecord[] = [];
    switch (column.columnType) {
      case "INT64":
        // 数値は初期値がNaNなので、数値(になり得る文字列)とそれ以外に仕分ける
        records = this.records
          .filter((item: CustomRecord) => isNaN(item[key]) === false)
          .sort((a, b) => {
            const aDash = new Number(a[key]) as number;
            const bDash = new Number(b[key]) as number;
            return aDash > bDash ? 1 * orderVal : -1 * orderVal;
          });
        emptyRecords = this.records.filter((item: CustomRecord) => isNaN(item[key]) === true);
        break;
      case "BOOL":
        records = this.records.sort((a, b) => {
          const aDash = a[key] === true ? 1 : 0;
          const bDash = b[key] === true ? 1 : 0;
          return aDash > bDash ? 1 * orderVal : -1 * orderVal;
        });
        break;
      case "STRING":
      case "CAPTION":
        // STRINGはデフォルト値が""(空)なので、空とそうじゃないのを仕分ける
        records = this.records
          .filter(
            (item: CustomRecord) =>
              item[key] !== "" && item[key] !== null && item[key] !== undefined
          )
          .sort((a, b) => {
            const aDash = a[key];
            const bDash = b[key];
            return aDash > bDash ? 1 * orderVal : -1 * orderVal;
          });
        emptyRecords = this.records.filter(
          (item: CustomRecord) => item[key] === "" || item[key] === null || item[key] === undefined
        );
        break;
    }
    this.records = orderVal === -1 ? records.concat(emptyRecords) : emptyRecords.concat(records);
    this.updateFilterRecords();
  }

  async uploadFile(f: File, url: string): Promise<void> {
    console.log("uploadFile start", f.name, url);
    // 一時URLへのアップロード実行
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const req: AxiosRequestConfig = {
          method: "PUT",
          url: url,
          data: e.target.result,
        };
        await axios(req)
          .then(() => {
            resolve();
          })
          .catch((e) => {
            if (e instanceof Error) {
              reject(e);
            }
            reject(Error(e));
          });
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsArrayBuffer(f);
    });
  }

  // S3へファイルをアップロード
  async uploadHostingImage(f: File): Promise<void> {
    // S3一時アップロード用URL発行要求
    const res = await requiredAuth<ControllersGetUploadImagePathOutput>(
      "get",
      `${import.meta.env.VITE_APP_API_BASE}v2/companies/${
        this.poiGroupId
      }/storePageHosting/uploadImagePath/${f.name}`
    );
    if (res == null || res.data == null) {
      throw Error("権限不足");
    }
    if (res.data.url == "") {
      throw Error("アップロード用URLが空だった");
    }
    // S3へのアップロード
    await this.uploadFile(f, res.data.url);
    // アップロード完了後の公開位置へのデプロイ
    const param = {};
    let counter = 0;
    const loop = true;
    while (loop) {
      counter++;
      const state = await requiredAuth<ControllersDeploymentImageFilesOutput>(
        "post",
        `${import.meta.env.VITE_APP_API_BASE}v2/companies/${
          this.poiGroupId
        }/storePageHosting/uploadImageDeployment/${f.name}`,
        param
      );
      if (state == null || state.data == null) {
        throw Error("権限不足");
      }
      if (!state.data.isRunning) {
        return;
      }
      param["processing"] = 1;
      if (counter > 60) {
        // 5分以上であればギブアップ
        throw Error(
          "アップロードリトライオーバー。一度にアップロードするファイルを減らすなどして、再実行してください。"
        );
      }
      // 処理中状態を追跡するために、５秒間隔で再度呼び出す
      await sleep(5000);
    }
  }

  async clearCDNCache(): Promise<void> {
    const param = {};
    let counter = 0;
    const loop = true;
    while (loop) {
      counter++;
      const state = await requiredAuth<ControllersCDNCacheCleanOutput>(
        "put",
        `${import.meta.env.VITE_APP_API_BASE}v2/companies/${
          this.poiGroupId
        }/storePageHosting/cacheClear`,
        param
      );
      if (state == null || state.data == null) {
        throw Error("権限不足");
      }
      if (!state.data.isRunning) {
        return;
      }
      param["processing"] = 1;
      if (counter > 60) {
        throw Error("CDN cache clearリトライオーバー。再度お試しください。");
      }
      // 処理中状態を追跡するために、1秒間隔で再度呼び出す
      await sleep(1000);
    }
  }

  // 画像・動画のホスティング(ファイル選択した後に呼び出される)
  async hostingImageUpload(): Promise<void> {
    // 選択したファイルが無かった場合は終了
    if (this.uploadFiles.length == 0) {
      return;
    }
    try {
      this.showImportDialog("画像アップロード", `アップロード中`, "");
      // 選択したファイルを並列でアップロード
      const wait_list: Promise<void>[] = [];
      this.uploadFiles.forEach((f) => {
        wait_list.push(this.uploadHostingImage(f));
      });
      await Promise.all(wait_list);
      // CDNキャッシュクリア
      if (
        this.company.custom?.cloudfrontDistributionID?.length > 0 &&
        this.company.custom?.cloudfrontDistributionPath?.length > 0
      ) {
        await this.clearCDNCache();
      }
      this.showImportDialog("画像アップロード", `アップロードが完了しました`, "OK");
    } catch (e) {
      this.importDialog.show = false;
      throw e;
    }
  }

  private imageFolders: Set<string> = new Set();
  private imageFileList: string[][] = [];

  async hostingImageListDownloadRaw(target: string = "", startKey: string = ""): Promise<void> {
    const lock = target + ":" + startKey;
    this.imageFolders.add(lock);

    // 画像一覧取得
    const param = {
      limit: 10,
    };
    if (target !== "") {
      param["target"] = target;
    }
    if (startKey != "") {
      param["startKey"] = startKey;
    }
    await requiredAuth<ControllersGetImagesOutput>(
      "get",
      `${import.meta.env.VITE_APP_API_BASE}v2/companies/${this.poiGroupId}/storePageHosting/images`,
      param
    ).then((res) => {
      if (res == null || res.data == null) {
        throw Error("権限不足");
      }
      if (res.status !== 200) {
        // lambdaの応答が200以外の場合はエラーとして扱う
        throw Error(JSON.parse(res?.request?.response)?.errorMessage);
      }
      res.data.files.forEach((item) => {
        if (item.isDir) {
          this.hostingImageListDownloadRaw(item.name);
        } else {
          this.imageFileList.push([
            item.url,
            item.name.replace(/^\//, ""),
            item.size.toString(),
            item.lastUpdate,
          ]);
        }
      });
      if (res.data.isNext) {
        this.hostingImageListDownloadRaw(target, res.data.files[res.data.files.length - 1].name);
      }
    });
    this.imageFolders.delete(lock);
    if (this.imageFolders.size == 0) {
      this.imageFileList.sort((a, b): number => {
        if (a[0] < b[0]) {
          return -1;
        }
        if (a[0] > b[0]) {
          return 1;
        }
        return 0;
      });
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(this.imageFileList);
      ws["!cols"] = [{ wpx: 600 }, { wpx: 350 }, { wpx: 100 }, { wpx: 120 }];
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const datetime = dayjs().format("YYYYMMDD");
      XLSX.writeFile(
        wb,
        `${wordDictionary.service.name}-公開画像一覧-${this.company.name}-${datetime}.xlsx`
      );
    }
  }

  async hostingImageListDownload(): Promise<void> {
    if (this.imageFolders.size > 0) {
      // 処理中と判断して実行しない
      return;
    }
    this.imageFileList = [
      [
        "URL(こちらを使用してください)",
        "FileName(アップロードした際のファイル名)",
        "FileSize",
        "LastUpdate",
      ],
    ];
    await this.hostingImageListDownloadRaw();
  }
}
export default toNative(StorepageHosting);
</script>

<style lang="scss" scoped>
.container-area {
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.custom-grid {
  box-sizing: border-box;
  height: calc(100vh - 230px);
  position: absolute !important;
  top: 50px;
  left: 0;
  right: 0;
  bottom: -70px;
}

.grid-control {
  font-size: small;
}
</style>
