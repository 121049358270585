import type { AxiosError } from "axios";
import { useSnackbar } from "@/storepinia/snackbar";
import { requiredAuth } from "@/helpers";
import { captureAndThrow } from "@/helpers/error";
import { Component, Vue, Watch, toNative } from "vue-facing-decorator";
import * as filters from "@/helpers/filters";
import TipableTitleGroup from "@/models/tipable-title-group";
import type {
    EntitiesArea,
    EntitiesFoodMenuStore,
    EntitiesStore,
    EntitiesStoresResponse,
    EntitiesStorePostResponse,
    EntitiesRival,
    MybusinessbusinessinformationMetadata,
} from "@/types/ls-api";
import wordDictionary from "@/word-dictionary";
import ModalForm from "./modal.vue";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { trimLocationName } from "@/helpers/gmb";
import { api } from "@/helpers/api/food-menu";
import { read, arrayBufferToCsv, addCommentToCell, getXLSXColumnName } from "@/helpers/xlsxtools";
import { sentimentLevels } from "@/const";
import { saveAs } from "file-saver";
import { action, getter, useIndexedDb } from "@/storepinia/idxdb";
import { getOperationLogParams } from "@/routes/operation-log";

// XLSXでのグループ設定上限数
const maxGroupCount = 10;
// 星数
const starCount = 5;
// XLSXでのクチコミアラートキーワード設定上限数
const maxReviewAlertKeywordCount = 5;

// XLSXのヘッダ行(上段)
const columnsTop = (() => {
    // 店舗ID,店舗コード,店舗名
    const columns: string[] = ["", "", ""];
    // グループカラムを追加
    for (let i = 0; i < maxGroupCount; i++) {
        columns.push(i === 0 ? "グループ" : "");
    }
    columns.push("メニュー");
    // 星数カラムを追加
    for (let i = 1; i <= starCount; i++) {
        columns.push(i === 1 ? "星数（クチコミアラート条件）" : "");
    }
    // 感情スコアカラムを追加
    for (let i = 1; i <= sentimentLevels.length; i++) {
        columns.push(i === 1 ? "感情スコア（クチコミアラート条件）" : "");
    }
    // クチコミアラートキーワードカラムを追加
    for (let i = 1; i <= maxReviewAlertKeywordCount; i++) {
        columns.push(i === 1 ? "キーワード（クチコミアラート条件）" : "");
    }
    return columns;
})();

// XLSXのヘッダ行(上段)のセル結合条件
const cellMergeConditions = (() => {
    const conditions = [] as XLSX.Range[];
    columnsTop.forEach((column, index) => {
        let count = 0;
        if (column === "グループ") {
            count = maxGroupCount;
        } else if (column === "星数（クチコミアラート条件）") {
            count = starCount;
        } else if (column === "感情スコア（クチコミアラート条件）") {
            count = sentimentLevels.length;
        } else if (column === "キーワード（クチコミアラート条件）") {
            count = maxReviewAlertKeywordCount;
        } else {
            return;
        }
        // indexは0始まりで整合性が取れないのでendの値は-1する
        conditions.push({
            s: { c: index, r: 0 },
            e: { c: index + count - 1, r: 0 },
        });
    });
    return conditions;
})();

// XLSXのヘッダ行(下段)
const columns = (() => {
    const columns: string[] = ["店舗ID", "店舗コード", "店舗名"];
    // グループカラムを追加
    for (let i = 0; i < maxGroupCount; i++) {
        columns.push(`グループ${i + 1}`);
    }
    columns.push("メニュー");
    // 星数カラムを追加
    for (let i = 1; i <= starCount; i++) {
        columns.push(`星数${i}`);
    }
    // 感情スコアカラムを追加
    sentimentLevels.forEach((level) => {
        columns.push(level.name);
    });
    // クチコミアラートキーワードカラムを追加
    for (let i = 1; i <= maxReviewAlertKeywordCount; i++) {
        columns.push(`キーワード${i}`);
    }
    columns.push("競合店舗名");
    columns.push("検索キーワード");
    return columns;
})();

const poiIDIndex = columns.indexOf("店舗ID");
const poiNameIndex = columns.indexOf("店舗名");
// グループ設定のカラムのindex
const groupColumnStartIndex = columns.indexOf("グループ1");
const groupColumnEndIndex = columns.indexOf("グループ" + maxGroupCount);
// メニュー設定のカラムのindex
const foodMenuIndex = columns.indexOf("メニュー");
// 星数カラムのindex
const starColumnStartIndex = columns.indexOf("星数1");
const starColumnEndIndex = columns.indexOf("星数" + starCount);
// 感情スコアカラムのindex
const sentimentColumnStartIndex = columns.indexOf(sentimentLevels[0].name);
const sentimentColumnEndIndex = columns.indexOf(sentimentLevels[sentimentLevels.length - 1].name);
// クチコミアラートキーワードカラムのindex
const reviewAlertKeywordStartIndex = columns.indexOf("キーワード1");
const reviewAlertKeywordEndIndex = columns.indexOf("キーワード" + maxReviewAlertKeywordCount);
// 競合店舗名のindex
const rivalNameIndex = columns.indexOf("競合店舗名");
// 競合店検索キーワードのindex
const rivalKeywordIndex = columns.indexOf("検索キーワード");

@Component({
    components: { ModalForm },
})
class Stores extends Vue {
    company = getter().company;
    areas = getter().areas;
    poiGroupId = getter().poiGroupId;
    canShowArea = getter().canShowArea;
    isComUser = getter().isComUser;
    setAreas = action().setAreas;
    setAreaStores = action().setAreaStores;
    setStores = action().setStores;
    setStoresCurrentPage = action().setStoresCurrentPage;
    addSnackbarMessages = useSnackbar().addSnackbarMessages;
    reviewAlertConditionFormat = filters.reviewAlertConditionFormat;
    canShowMenu = getter().canShowMenu;
    canManageMenu = getter().canManageMenu;
    canShowRivals = getter().canShowStoreRivals;
    canManageRivals = getter().canManageStoreRivals;

    areaTagId: string = null;
    foodMenuGroupId: number = null;
    foodMenuGroups: { id: number; title: string }[] = [];
    locationMetadatas: { [poiId: number]: MybusinessbusinessinformationMetadata } = {};
    foodMenuStores: { [poiId: number]: EntitiesFoodMenuStore } = {};
    checkedRows = [];
    loading: boolean = false;
    errMessage: string;
    stores: EntitiesStore[] = [];
    currentPage: number = getter().storesCurrentPage;
    perPage: number = 50;
    isModalActive: boolean = false;
    xlsxFile: File = null;
    dialog = {
        show: false,
        percentage: 0,
        title: "",
        message: "",
        cancelButton: "",
        acceptButton: "",
        acceptAction: null,
    };
    confirmDialogVisible = false;
    dict = wordDictionary.stores;
    titles = new TipableTitleGroup([
        wordDictionary.stores.storeCode,
        wordDictionary.stores.poiName,
        wordDictionary.stores.latitude,
        wordDictionary.stores.longitude,
        wordDictionary.stores.areaTag,
        wordDictionary.stores.foodMenuGroup,
        wordDictionary.stores.alertCondition,
        wordDictionary.stores.rivalName,
        wordDictionary.stores.rivalKeyword,
        wordDictionary.stores.edit,
    ]);
    areaNames = {};
    editRows: any[] = [];
    newAreaNames: string[] = [];
    searchWord = "";
    cachedStores: EntitiesStore[] = [];

    async created(): Promise<void> {
        this.loading = true;
        try {
            // メニュー権限を持っているときのみ情報取得する
            if (this.canShowMenu) {
                // メニューグループ一覧を取得
                this.foodMenuGroups = [{ id: 0, title: "設定なし" }];
                const oplogParams = getOperationLogParams(this.$route, "get", "foodmenu_list");
                const fmgs = await api.getFoodMenuGroups(this.poiGroupId, oplogParams);
                fmgs.sort((a, b) => (a.title < b.title ? -1 : 1));
                for (const fmg of fmgs) {
                    this.foodMenuGroups.push({
                        id: fmg.foodMenuGroupID,
                        title: fmg.title,
                    });
                }
                // メニュー店舗設定を取得
                const fmss = await api.getFoodMenuStores(this.poiGroupId);
                for (const fms of fmss) {
                    this.foodMenuStores[fms.poiID] = fms;
                }
            }

            const areaIds = Object.keys(this.areas);
            if (areaIds.length > 0) {
                // グループ管理ページでグループ名選択して遷移してきた場合はそのグループをareaTagIdに代入する
                this.areaTagId = this.$route.query.areaId
                    ? (this.$route.query.areaId as string)
                    : null;
            }
            await this.onPageChange(this.currentPage);
        } finally {
            this.loading = false;
        }
    }

    @Watch("areas", { immediate: true })
    reloadAreas(): void {
        // グループ一覧からグループ名→poiIDの辞書を作成
        this.areaNames = {};
        for (const areaID in this.areas) {
            this.areaNames[this.areas[areaID]] = parseInt(areaID, 10);
        }
    }

    get areaOptions(): { title: string; value: string }[] {
        return Object.keys(this.areas).map((areaID) => ({
            title: this.areas[areaID],
            value: areaID,
        }));
    }

    @Watch("currentPage", { immediate: true })
    setCurrentPage(): void {
        this.setStoresCurrentPage(this.currentPage);
    }

    async onPageChange(page: number): Promise<void> {
        this.currentPage = page;
        await this.fetchStores();
    }

    async fetchStores(): Promise<void> {
        this.loading = true;
        try {
            const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
                this.poiGroupId
            }/storetags`;
            const response = await requiredAuth<EntitiesStoresResponse>("get", url);
            if (response == null || response.data == null) {
                this.stores = [];
            } else {
                this.stores = response.data.stores
                    .filter((store) => store.enabled)
                    .map((store) => {
                        store.areas?.sort((a, b) => a - b);
                        return store;
                    });
                // 店舗データをキャッシュする
                this.cachedStores = [...this.stores];
                // クチコミアラート設定ダイアログを閉じた時もfetchが走るので検索しとく
                this.searchStores();
            }
            // locations.metadata を取得する
            const locationNames = this.cachedStores.map((s) => trimLocationName(s.gmbLocationID));
            const ls = await api.getLocationInParallel(
                this.poiGroupId,
                this.company.gmbAccount,
                locationNames,
                "name,metadata",
                100,
                10
            );
            const m: { [locname: string]: MybusinessbusinessinformationMetadata } = {};
            ls?.forEach((l) => (m[l.location.name] = l.location.metadata));
            this.cachedStores?.forEach(
                (s) => (this.locationMetadatas[s.poiID] = m[trimLocationName(s.gmbLocationID)])
            );
        } catch (e) {
            console.error(e);
            throw Error(this.generateErrorMessage(e as AxiosError));
        } finally {
            this.loading = false;
        }
    }

    getRivalNameAndKeyword(rivals: EntitiesRival[]): Pick<EntitiesRival, "name" | "keyword"> {
        // 画面はまだ複数の競合店に対応していない
        if (rivals == null) {
            return { name: "", keyword: "" };
        } else {
            return { name: rivals[0]?.name, keyword: rivals[0]?.keyword };
        }
    }

    async toggle(row: EntitiesStore, needsUpdateVuex: boolean = true): Promise<void> {
        // オプションを配列にして、送信用のオブジェクトを作成する
        const postdata: EntitiesStore = Object.assign({}, row);

        const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
            this.poiGroupId
        }/storetags/${row.poiID}`;

        this.loading = true;
        try {
            const response = await (
                await requiredAuth<EntitiesStorePostResponse>("put", url, null, postdata)
            ).data;
            if (response.poiID !== -1) {
                row.poiID = response.poiID;
                if (needsUpdateVuex) {
                    this.setStores(this.poiGroupId);
                    this.setAreaStores(this.poiGroupId);
                }
            }
        } catch (e) {
            this.errorToast(e as AxiosError);
            await this.onPageChange(this.currentPage); // エラー時のみ取り直し
            // throwしていないので、エラーが出ても呼び出し元の処理は続行する
        } finally {
            this.loading = false;
        }
    }

    generateErrorMessage(error: AxiosError<any>): string {
        if (!error.response) {
            return "Error: no response";
        }
        // note: APIのレスポンスのメッセージは長いことがあるので、トースト等に出すには短くする必要がある
        const errMessage = (error?.response?.data?.errorMessage ?? "") as string;
        console.error(errMessage);
        return wordDictionary.stores.generateErrorMessage_message;
    }

    errorToast(e: AxiosError): void {
        this.errMessage = this.generateErrorMessage(e);
        this.addSnackbarMessages({
            text: this.errMessage,
            color: "danger",
            options: {
                top: false,
            },
        });
    }

    async assignAreaTags(e: PointerEvent): Promise<void> {
        // 検索キーワードボックスでEnterキー押すと何故か発火してしまうのを防止する
        if (e.pointerType === "") {
            return;
        }
        if (this.checkedRows.length > 0 && this.areaTagId !== null) {
            this.loading = true;
            const promises = this.checkedRows.map(async (row) => {
                if (row.areas == null || row.areas.indexOf(parseInt(this.areaTagId, 10)) === -1) {
                    if (row.areas == null) {
                        row.areas = [];
                    }
                    row.areas.push(parseInt(this.areaTagId, 10));
                    row.areas.sort((a, b) => a - b);
                    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
                        this.poiGroupId
                    }/storetags/${row.poiID}`;
                    return await requiredAuth("put", url, null, row);
                }
            });
            try {
                const response = await Promise.all(promises.filter(Boolean));
                const updateCount = response.filter((r) => r != null && r.data != null).length;
                this.addSnackbarMessages({
                    text: `${updateCount}店舗にグループを設定しました`,
                    color: "success",
                });
                this.setAreaStores(this.poiGroupId);
                this.setStores(this.poiGroupId);
            } catch (e) {
                console.error(e);
                await this.onPageChange(this.currentPage); // エラー時のみ取り直し
                throw Error(this.generateErrorMessage(e as AxiosError));
            } finally {
                this.loading = false;
            }
        }
    }

    async withdrawAreaTag(row: EntitiesStore, areaId: number): Promise<void> {
        this.loading = true;
        try {
            row.areas = row.areas.filter((id) => id !== areaId);
            const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
                this.poiGroupId
            }/storetags/${row.poiID}`;
            const response = await requiredAuth("put", url, null, row);
            if (response == null || response.data == null) {
                await this.onPageChange(this.currentPage);
            } else {
                this.setAreaStores(this.poiGroupId);
                this.setStores(this.poiGroupId);
            }
        } catch (e) {
            console.error(e);
            await this.onPageChange(this.currentPage); // エラー時のみ取り直し
            throw Error(this.generateErrorMessage(e as AxiosError));
        } finally {
            this.loading = false;
        }
    }

    async assignFoodMenuGroup(e: PointerEvent): Promise<void> {
        // 検索キーワードボックスでEnterキー押すと何故か発火してしまうのを防止する
        if (e.pointerType === "") {
            return;
        }
        // チェックのついた店舗から canHaveFoodMenus == true の店舗だけ抽出する
        const poiIDs: number[] = this.checkedRows
            .map((row) => row.poiID)
            .filter((id) => this.locationMetadatas[id]?.canHaveFoodMenus);
        if (poiIDs.length === 0) {
            this.addSnackbarMessages({
                text: "選択した店舗にメニューを設定できるものはありませんでした",
                color: "warning",
            });
            return;
        }
        this.loading = true;
        const fmss = await api.putFoodMenuStores(this.poiGroupId, poiIDs, this.foodMenuGroupId);
        for (const fms of fmss) {
            this.foodMenuStores[fms.poiID] = fms;
        }
        this.loading = false;
        this.checkedRows.splice(0);
        this.addSnackbarMessages({
            text: `${fmss.length} 店舗のメニュー設定を更新しました。`,
            color: "success",
        });
    }

    openReviewAlertModal(): void {
        const disabledStoreExists = this.checkedRows?.some(
            (cr) => !cr.options?.some((opt) => opt === "review")
        );
        if (disabledStoreExists) {
            this.$router.push({ name: "InquiriesForm", query: { from: this.$route.path } });
            return;
        }

        this.isModalActive = true;
    }

    get storeSelected(): boolean {
        return this.checkedRows?.length >= 1;
    }

    async reviewAlertConditionSubmit(reviewAlertCondition: any): Promise<void> {
        const storePuts = this.checkedRows.map((row) => {
            return async () => {
                try {
                    await requiredAuth<EntitiesStore>(
                        "put",
                        `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
                            this.poiGroupId
                        }/storetags/${row.poiID}`,
                        null,
                        {
                            alertStarRatings: reviewAlertCondition.alertStarRatings,
                            alertSentimentRanges: reviewAlertCondition.alertSentimentRanges,
                            alertKeywords: reviewAlertCondition.alertKeywords,
                        }
                    );
                } catch (e) {
                    console.error(e);
                    captureAndThrow("店舗クチコミアラート条件更新失敗", e);
                }
            };
        });

        this.loading = true;
        this.isModalActive = false;

        for (const sp of storePuts) {
            await sp();
        }

        this.checkedRows.splice(0); // モーダルクローズ時のテーブルの自動的なクリアが反映されないため、明示的にクリア

        await this.onPageChange(this.currentPage); // エラー時のみ取り直し
        this.loading = false;

        this.addSnackbarMessages({
            text: "ご指定の店舗のクチコミアラート条件を更新しました。",
            color: "success",
        });
    }

    private getFoodMenuGroupID(groupName: string): number {
        if (groupName.length === 0 || groupName === "-") {
            return 0;
        }
        for (const group of this.foodMenuGroups) {
            if (group.title === groupName) {
                return group.id;
            }
        }
        return -1;
    }

    private getXlsxCellMessage(rowIndex, ColumnIndex: number): string {
        return `（${parseInt(rowIndex, 10) + 3}行目${getXLSXColumnName(ColumnIndex)}列）\n`;
    }

    async importFile(): Promise<void> {
        if (!this.xlsxFile) {
            return;
        }
        // xlsx読み取り
        const file: File = this.xlsxFile as any;
        let fields: string[];
        let rows: any[];
        try {
            const buf = await read(file);
            this.xlsxFile = null;
            [fields, rows] = arrayBufferToCsv(buf, 2);
            if (fields.length === 0) {
                this.setDialog(
                    "XLSXインポート",
                    "XLSXにヘッダ行 がありません。1〜2行目は消さないでください。"
                );
                return;
            }
        } catch (e) {
            console.error(e);
            this.setDialog(
                "XLSXインポート",
                `XLSXの読み込みに失敗しました。エラー:${(e as AxiosError).message}`
            );
            return;
        }
        // XLSXのカラム(fields)とcolumnsが合致しなかったらエラーとする
        for (const column of columns) {
            if (fields.includes(column) === false) {
                this.setDialog(
                    "XLSXインポート",
                    `XLSXの2行目に ${column} がありません。1〜2行目は消さないでください。`
                );
                return;
            }
        }

        if (rows.length === 0) {
            this.setDialog("XLSXインポート", `XLSXの3行目以降に更新データがありません。`);
            return;
        }

        // poiID、グループ、メニュー、星数、感情スコアのバリデーション
        const results = this.stores;
        const newAreaNames: string[] = [];
        const br = new RegExp(/\r*\n/);

        for (const [rowIndex, row] of Object.entries(rows)) {
            const poiID = row[columns[poiIDIndex]];
            if (!results.find((r) => r.poiID === parseInt(poiID, 10))) {
                const message =
                    `XLSXに不明な店舗ID:${poiID}があります` +
                    this.getXlsxCellMessage(rowIndex, poiIDIndex) +
                    "画面をリロードしてからもう一度エクスポートしてください";
                this.setDialog("XLSXインポート", message);
                return;
            }

            // 店舗名に改行入っていないかチェック
            const storeName = row[columns[poiNameIndex]];
            if (br.test(storeName)) {
                const message =
                    `店舗 (ID:${poiID})の店舗名に改行が含まれています` +
                    this.getXlsxCellMessage(rowIndex, poiNameIndex) +
                    "改行を取り除いてからもう一度インポートしてください";
                this.setDialog("XLSXインポート", message);
                return;
            }

            // 新グループを記録
            for (let i = groupColumnStartIndex; i <= groupColumnEndIndex; i++) {
                const areaName = row[columns[i]];
                // グループに改行が入ってないかチェック
                if (br.test(areaName)) {
                    const message =
                        `店舗 (ID:${poiID})のグループに改行が含まれています` +
                        this.getXlsxCellMessage(rowIndex, i) +
                        "改行を取り除いてからもう一度インポートしてください";
                    this.setDialog("XLSXインポート", message);
                    return;
                }
                if (areaName !== "" && !(areaName in this.areaNames)) {
                    newAreaNames.push(areaName);
                }
            }
            // メニューのバリデーション
            const xlsxFoodMenuName = row[columns[foodMenuIndex]];
            if (
                xlsxFoodMenuName.length > 0 &&
                xlsxFoodMenuName !== "-" &&
                !this.locationMetadatas[poiID]?.canHaveFoodMenus
            ) {
                const message =
                    `メニューが設定できない店舗 (ID:${poiID}) にメニューが設定されています` +
                    this.getXlsxCellMessage(rowIndex, foodMenuIndex) +
                    "XLSXを修正してからもう一度インポートしてください";
                this.setDialog("XLSXインポート", message);
                return;
            }
            if (
                xlsxFoodMenuName.length > 0 &&
                xlsxFoodMenuName !== "-" &&
                this.getFoodMenuGroupID(xlsxFoodMenuName) < 0
            ) {
                const message =
                    `XLSXに未登録のメニュー (${xlsxFoodMenuName}) が設定されています` +
                    this.getXlsxCellMessage(rowIndex, foodMenuIndex) +
                    "先にメニューを登録してからもう一度インポートしてください";
                this.setDialog("XLSXインポート", message);
                return;
            }
            // 星数・感情スコアが 空文字か"o"でなければエラーとみなす
            for (let i = starColumnStartIndex; i <= sentimentColumnEndIndex; i++) {
                if (row[columns[i]] !== "" && row[columns[i]] !== "o") {
                    const message =
                        `星数・感情スコアは 空文字か「o」で記載してください` +
                        this.getXlsxCellMessage(rowIndex, i);
                    this.setDialog("XLSXインポート", message);
                    return;
                }
            }
        }
        this.editRows = rows;
        this.newAreaNames = Array.from(new Set(newAreaNames)); // 重複排除
        if (this.newAreaNames.length > 0) {
            const message =
                `新しいグループが入力されています。\n以下の${this.newAreaNames.length}グループを新規登録して続行してもよろしいですか？\n` +
                `${"　"}\n` +
                `${this.newAreaNames.join(", ")}`;
            this.setDialog("XLSXインポート", message, "OK", "キャンセル", this.importFileExec);
            return;
        } else {
            await this.importFileExec(this.editRows, this.newAreaNames);
        }
    }

    async importFileExec(rows: any[], newAreaNames: string[]): Promise<void> {
        const results = this.stores;
        // 新グループを登録
        for (const areaName of newAreaNames) {
            try {
                this.setDialog("XLSXインポート", `${areaName}を登録中`, "", "");
                await this.postNewArea(areaName).then(
                    (areaID) => (this.areaNames[areaName] = areaID)
                );
            } catch (e) {
                const message =
                    `${areaName}の登録時にエラー発生。` +
                    `${
                        (e as AxiosError<{ errorMessage: string }>)?.response?.data?.errorMessage ??
                        (e as AxiosError).message
                    }`;
                this.setDialog("XLSXインポート", message);
                return;
            }
        }
        this.setAreas(this.poiGroupId);
        // 更新された行をテーブルに反映する
        const updates: EntitiesStore[] = []; // テーブルに反映した行を保持しておいて更新処理で用いる
        const foodMenuUpdates: { [foodMenuGroupID: number]: number[] } = {};
        for (const row of rows) {
            const poiID = row[columns[poiIDIndex]];
            const result = results.find((r) => r.poiID === parseInt(poiID, 10));
            if (result.rivals == null) {
                result.rivals = [{ name: "", keyword: "" }];
            }
            const before: EntitiesStore = JSON.parse(JSON.stringify(result));

            result.areas = this.getAreas(row);
            // クチコミオプション契約が必要
            if (result?.options?.some((opt) => opt === "review")) {
                result.alertStarRatings = this.getAlertStarRatings(row);
                result.alertSentimentRanges = this.getAlertSentimentRanges(row);
                result.alertKeywords = this.getAlertKeywords(row);
            }
            // 管理者権限が必要
            if (this.isComUser) {
                result.rivals[0].name = row[columns[rivalNameIndex]];
                result.rivals[0].keyword = row[columns[rivalKeywordIndex]];
            }

            // 変更された行だけを更新
            if (!this.equalRow(before, result)) {
                updates.push(result);
            }

            // メニューの更新を確認
            const foodMenuGroupID = this.foodMenuStores[poiID]?.foodMenuGroupID ?? 0;
            const xlsxFoodMenuGroupID = this.getFoodMenuGroupID(row[columns[foodMenuIndex]]);
            if (xlsxFoodMenuGroupID > -1 && xlsxFoodMenuGroupID !== foodMenuGroupID) {
                const poiIDs: number[] = foodMenuUpdates[xlsxFoodMenuGroupID]
                    ? foodMenuUpdates[xlsxFoodMenuGroupID]
                    : [];
                poiIDs.push(poiID);
                foodMenuUpdates[xlsxFoodMenuGroupID] = poiIDs;
            }
        }

        // ダイアログを表示
        this.setDialog("変更内容を反映中");
        let count = 0;
        let foodMenuUpdatesTotal = 0;
        // 1行ずつ反映していく
        try {
            for (const update of updates) {
                this.dialog.message = `${update?.name} を更新中`;
                await this.toggle(update, false);
                // プログレスバーを進捗させる
                this.dialog.percentage = (++count / updates.length) * 100;
            }
            this.dialog.percentage = 100;
            this.dialog.message = `${updates.length} 店舗の更新が完了しました`;

            // Vuexをまとめて更新
            this.setStores(this.poiGroupId);
            this.setAreaStores(this.poiGroupId);

            // メニューの更新
            count = 0;
            for (const foodMenuGroupID of Object.keys(foodMenuUpdates)) {
                foodMenuUpdatesTotal += foodMenuUpdates[foodMenuGroupID].length;
            }
            for (const foodMenuGroupID of Object.keys(foodMenuUpdates)) {
                this.dialog.message = "メニューを更新中";
                const fmss = await api.putFoodMenuStores(
                    this.poiGroupId,
                    foodMenuUpdates[foodMenuGroupID],
                    Number(foodMenuGroupID)
                );
                for (const fms of fmss) {
                    this.foodMenuStores[fms.poiID] = fms;
                }
                count += foodMenuUpdates[foodMenuGroupID].length;
                this.dialog.percentage = (count / foodMenuUpdatesTotal) * 100;
            }
            if (foodMenuUpdatesTotal > 0) {
                this.dialog.percentage = 100;
                this.dialog.message = `${foodMenuUpdatesTotal} 店舗のメニュー更新が完了しました`;
            }
            this.dialog.acceptButton = "OK";
        } catch (e) {
            console.dir(e);
            throw Error("店舗情報一覧の更新に失敗しました。");
        } finally {
            if (foodMenuUpdatesTotal > 0) {
                await this.onPageChange(this.currentPage);
            }
        }
    }

    equalRow(before: EntitiesStore, after: EntitiesStore): boolean {
        return (
            this.equalArray(before?.areas, after?.areas) &&
            this.equalArray(before?.alertStarRatings, after?.alertStarRatings) &&
            this.equalArray(before?.alertSentimentRanges, after?.alertSentimentRanges) &&
            this.equalArray(before?.alertKeywords, after?.alertKeywords) &&
            this.equalRivals(before?.rivals[0], after?.rivals[0])
        );
    }

    equalArray<T>(before: T[] = [], after: T[] = []): boolean {
        if (before.length != after.length) {
            return false;
        }
        before = before.sort();
        after = after.sort();
        for (let i = 0; i < before.length; i++) {
            if (before[i] !== after[i]) {
                return false;
            }
        }
        return true;
    }

    equalRivals(before: EntitiesRival, after: EntitiesRival): boolean {
        if (before?.name !== after.name) {
            return false;
        }
        if (before?.keyword !== after.keyword) {
            return false;
        }
        return true;
    }

    getAreas(row: any[]): number[] {
        const array: number[] = [];
        for (let i = groupColumnStartIndex; i <= groupColumnEndIndex; i++) {
            if (row[columns[i]] !== "") {
                const areaName = row[columns[i]];
                array.push(this.areaNames[areaName]);
            }
        }
        // デフォルトでは文字列としてソートされてしまう
        return array.sort((a, b) => a - b);
    }

    getFoodMenuGroupTitle(poiId: number): string {
        if (!this.locationMetadatas[poiId]?.canHaveFoodMenus) {
            return "-";
        }
        const foodMenuGroupId = this.foodMenuStores[poiId]?.foodMenuGroupID ?? 0;
        return this.foodMenuGroups.find((x) => x.id === foodMenuGroupId)?.title ?? "エラー";
    }

    getAlertStarRatings(row: any[]): number[] {
        const array: number[] = [];
        for (let i = starColumnStartIndex; i <= starColumnEndIndex; i++) {
            if (row[columns[i]].trim() === "o") {
                array.push(i - starColumnStartIndex + 1);
            }
        }
        return array.filter((v) => v);
    }

    getAlertSentimentRanges(row: any[]): string[] {
        const array: string[] = [];
        for (let i = sentimentColumnStartIndex; i <= sentimentColumnEndIndex; i++) {
            if (row[columns[i]].trim() === "o") {
                array.push(sentimentLevels[i - sentimentColumnStartIndex].value);
            }
        }
        return array.filter((v) => v);
    }

    getAlertKeywords(row: any[]): string[] {
        const array: string[] = [];
        for (let i = reviewAlertKeywordStartIndex; i <= reviewAlertKeywordEndIndex; i++) {
            if (row[columns[i]].trim() !== "") {
                array.push(row[columns[i]]);
            }
        }
        return array;
    }

    async postNewArea(areaName: string): Promise<number> {
        const newArea: Partial<EntitiesArea> = {
            name: areaName,
        };
        const response = await requiredAuth<EntitiesArea>(
            "post",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/areas`,
            null,
            newArea
        );

        if (response == null || response.data == null) {
            // 権限不足
            return 0;
        }

        return response.data.areaID;
    }

    exportFile(): void {
        const companyName = useIndexedDb().company.name;
        const results = this.stores;
        // Sheet.js を用いて xlsx ファイルを作成する
        const aoa = []; // Array of arrays
        aoa.push(columnsTop);
        aoa.push(columns);
        for (const row of results) {
            const name = row.name ?? "";
            const arr = [];
            arr.push(row.poiID ?? "-1");
            arr.push(row.gmbStoreCode);
            arr.push(name);
            // グループ名（Maxを超える場合は先頭からMax個まで）
            const areaArray: string[] = row?.areas?.map((areaId) => this.areas[areaId]) ?? [];
            areaArray.push(...new Array(maxGroupCount));
            arr.push(...areaArray.slice(0, maxGroupCount));
            // メニュー
            const foodMenuGroupName = row.poiID ? this.getFoodMenuGroupTitle(row.poiID) : "";
            arr.push(foodMenuGroupName);
            // クチコミアラート設定 星数
            arr.push(
                ...[...new Array(starCount)].map((s, i) =>
                    row?.alertStarRatings?.includes(i + 1) ? "o" : ""
                )
            );
            // クチコミアラート設定 感情スコア
            arr.push(
                ...[...new Array(sentimentLevels.length)].map((s, i) =>
                    row?.alertSentimentRanges?.includes(sentimentLevels[i].value) ? "o" : ""
                )
            );
            // クチコミアラート設定 キーワード（Maxを超える場合は先頭からMax個まで）
            const keywordArray: string[] = [...(row?.alertKeywords ?? [])];
            keywordArray.push(...new Array(maxReviewAlertKeywordCount));
            arr.push(...keywordArray.slice(0, maxReviewAlertKeywordCount));
            // 競合店舗名
            arr.push(row?.rivals?.length > 0 ? row.rivals[0]?.name : "");
            // 競合店舗 検索キーワード
            arr.push(row?.rivals?.length > 0 ? row.rivals[0]?.keyword : "");
            aoa.push(arr);
        }
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(aoa);

        // セルのマージ設定
        ws["!merges"] = cellMergeConditions;

        // セルのコメント設定
        addCommentToCell(ws["A2"], "店舗IDは変更できません");
        addCommentToCell(ws["B2"], "店舗コードは変更できません");
        addCommentToCell(ws["C2"], "この画面から店舗名は変更できません");
        // セル結合したところには必ずコメントをつける想定
        const mergedCellsComments = [
            `この店舗の属するグループを${maxGroupCount}個まで入力（新グループは自動登録）`,
            "クチコミアラート条件にする星数に「o」を入力",
            "クチコミアラート条件にする感情スコアに「o」を入力",
            `クチコミアラート条件にするキーワードを${maxReviewAlertKeywordCount}個まで入力`,
        ];
        for (let i = 0; i < mergedCellsComments.length; i++) {
            addCommentToCell(
                ws[XLSX.utils.encode_cell(cellMergeConditions[i].s)],
                mergedCellsComments[i]
            );
        }

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const buf: ArrayBuffer = XLSX.write(wb, {
            type: "array",
            bookType: "xlsx",
            bookSST: true,
        });
        const datetime = dayjs().format("YYYYMMDD");
        saveAs(
            new Blob([buf], { type: "application/octet-stream" }),
            `${wordDictionary.service.name}-店舗設定一覧エクスポート-${companyName}-${datetime}.xlsx`
        );
    }

    setDialog(
        title: string,
        message: string = "",
        acceptButton: string = "OK",
        cancelButton: string = "",
        acceptAction = null
    ): void {
        this.dialog.show = true;
        this.dialog.title = title;
        this.dialog.message = message;
        this.dialog.acceptButton = acceptButton;
        this.dialog.cancelButton = cancelButton;
        this.dialog.percentage = 0;
        this.dialog.acceptAction = acceptAction;
    }

    async acceptDialog(): Promise<void> {
        this.dialog.show = false;
        if (this.dialog.acceptAction !== null) {
            await this.dialog.acceptAction(this.editRows, this.newAreaNames);
        }
    }

    searchStores(): void {
        let results: EntitiesStore[] = this.cachedStores;
        // 検索キーワードで絞り込み
        const searchWord = this.searchWord?.trim().toLowerCase();
        if (searchWord !== "") {
            results = results.filter((store) => {
                // 店舗名でマッチするか
                if (store.name.toLowerCase().includes(searchWord)) {
                    return true;
                }

                // 店舗コードでマッチするか
                if (store.gmbStoreCode?.toLowerCase().includes(searchWord)) {
                    return true;
                }

                // グループ名でマッチするか
                if (store.areas) {
                    for (const area of store.areas) {
                        const areaName: string = this.areas[area.toString()];
                        if (areaName?.toLowerCase().includes(searchWord)) {
                            return true;
                        }
                    }
                }

                // 競合店舗名と検索キーワードでマッチするか
                if (store.rivals) {
                    // 画面はまだ複数の競合店に対応していないため、先頭の 1 件のみ取得する
                    const rival = this.getRivalNameAndKeyword(store.rivals);
                    if (
                        rival.name?.toLowerCase().includes(searchWord) ||
                        rival.keyword?.toLowerCase().includes(searchWord)
                    ) {
                        return true;
                    }
                }

                // クチコミアラート星の数でマッチするか
                if (store.alertStarRatings) {
                    for (const numOfStars of store.alertStarRatings) {
                        if (searchWord === "★".repeat(numOfStars)) {
                            return true;
                        }
                    }
                }

                // クチコミアラート感情スコアでマッチするか
                if (store.alertSentimentRanges) {
                    for (const sentimentRange of store.alertSentimentRanges) {
                        for (const sentiLevel of sentimentLevels) {
                            if (sentiLevel.value !== sentimentRange) {
                                continue;
                            }
                            // レベルが一致してたら検索ワードを含んでるか調べる
                            if (sentiLevel.name?.toLowerCase().includes(searchWord)) {
                                return true;
                            }
                        }
                    }
                }

                // クチコミアラートキーワードでマッチするか
                if (store.alertKeywords) {
                    for (const alertKeyword of store.alertKeywords) {
                        if (alertKeyword?.toLowerCase().includes(searchWord)) {
                            return true;
                        }
                    }
                }

                // クチコミアラート条件が「通知しない」にマッチするか
                if (
                    searchWord === this.dict.noNotification &&
                    !store.alertKeywords &&
                    !store.alertSentimentRanges &&
                    !store.alertStarRatings
                ) {
                    return true;
                }

                return false;
            });
        }

        // 店舗ID順にソートする
        results.sort((a, b) => {
            return a.poiID > b.poiID ? 1 : -1;
        });
        this.stores = results;
    }
}
export default toNative(Stores);
